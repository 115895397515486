import util from "../utils/util";

export default class UtilsService {
  static CheckIp() {
    return util.sendApiRequest("/utils/checkip", "GET", true).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }
}
