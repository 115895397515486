import React from "react";
import Container from "react-bootstrap/Container";
import testimonial from "../../services/testimonialService";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Redirect } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default class Testimonial extends React.Component {
  constructor(props) {
    super(props);
    this.course_id = props.match.params.id;
    this.state = { testimonial: null, redirect: false, course: [] };
    this.state.testimonial = {
      name: "",
      company: "",
      content: "",
      view_on_homepage: false,
      is_active: false,
      course: "",
    };
    this.testiServ = new testimonial();
    this.schema = Yup.object({
      name: Yup.string().required("Name is a required field"),
      company: Yup.string(),
      content: Yup.string().required("Content is a required field"),
    });
  }
  submitTestiForm(values, actions) {
    values.course = this.course_id
    actions.setSubmitting(false);
      this.testiServ.addtestimonial(values).then(
        (response) => {
          setTimeout(()=>{
            this.setState({ redirect: true });
          },2000)
          toast.success("Successfully submitted");
        },
        (error) => {
          setTimeout(()=>{
            this.setState({ redirect: false });
          },2000)
          toast.error("Something went wrong, try again");
        }
      );
  }

  render() {
    if (this.state.redirect === true) {
      return <Redirect to={"/user/course/" + this.course_id + "/" + window.user.data.first_name} />;
    }
    return (
      <Formik
        validationSchema={this.schema}
        initialValues={this.state.testimonial}
        enableReinitialize={true}
        onSubmit={this.submitTestiForm.bind(this)}
        render={({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <div className="address addresslist">
            <Container>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col sm={12} className = 'testimonial-hdr'>
                    <h3> Testimonial Details</h3>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6}>
                    <Form.Group>
                      <Form.Label>Name*</Form.Label>
                      <Form.Control
                        type="text"
                        value={values.name}
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.name && !errors.name}
                      />
                      <ErrorMessage name="name">
                        {(msg) => <div className="err_below">{msg}</div>}
                      </ErrorMessage>
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6}>
                    <Form.Group>
                      <Form.Label>Company</Form.Label>
                      <Form.Control
                        type="text"
                        value={values.company}
                        name="company"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.value && !errors.value}
                      />
                      <ErrorMessage name="company">
                        {(msg) => <div className="err_below">{msg}</div>}
                      </ErrorMessage>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6}>
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                      <Form.Label>Enter text*</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows="3"
                        value={values.content}
                        name="content"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.content && !errors.content}
                      />
                      <ErrorMessage name="content">
                        {(msg) => <div className="err_below">{msg}</div>}
                      </ErrorMessage>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={4}></Col>
                  <Col sm={12} md={4} className = 'testimonial-btn'>
                    <button
                      className="btn btn-lg btn-primary btn-block setbgcolor"
                      type="submit"
                    >
                      Submit
                    </button>
                  </Col>
                </Row>
              </Form>
            </Container>
            <ToastContainer />
          </div>
        )}
      />
    );
  }
}