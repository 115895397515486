import util from "../utils/util";

export default class BlogService {
  getBlog(id) {
    return util.sendApiRequest("/blogs/" + id, "GET", true).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }
  listBlog(start, length, type, searchTxt, searchField) {
    let url = "/blogs/list/" + start + "/" + length + "/" + type;

    return util.sendApiRequest(url, "GET", true).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }
  joinMeeting(data) {
    return util.sendApiRequest("/blogs/join-metting", "POST", true, data).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }
}
