import React from 'react';


export default class ForgetPasswordConfirmation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
render(){
    return(
        <div className = "fgtpswdcnf">
            <h2>Forgot Password</h2>
               <strong>You should soon receive an email allowing you to reset your password.<br /> Please make sure to check your spam and trash if you can't find the email.</strong> 
        </div>
    )
 }
}