import util from "../utils/util";

export default class OrderService {
  getOrder(id) {
    return util.sendApiRequest("/order/" + id, "GET", true).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }

  listOrder(start, length, searchTxt, searchField) {
    let url = "/order/list/" + start + "/" + length;
    if (searchTxt && searchField) {
      url = "/order/list/" + start + "/" + length + "/" + searchTxt + "/" + searchField.toLowerCase();
    }

    return util.sendApiRequest(url, "GET", true).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }
  addOrder(order) {
    return util.sendApiRequest("/order", "POST", true, order).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }
  getUserOrder(id) {
    return util.sendApiRequest("/order/getuser/" + id, "GET", true).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }
  previewOrder(order) {
    return util.sendApiRequest("/order/previeworder", "POST", true, order).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }

  paymentCapture(paymentcapture) {
    return util.sendApiRequest("/order/payment", "POST", true, paymentcapture).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }

  paymentCancelled(order_id) {
    return util.sendApiRequest("/order/cancel", "POST", true, { order_id: order_id }).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }
  getOrderByToken(token) {
    return util.sendApiRequest("/order/tk/" + token, "GET", true).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }
}
