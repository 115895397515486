import util from "../utils/util";

export default class LiveSession {
  liveSessionReport(data) {
    const livesession = Object.keys(data).reduce((object, key) => {
      if (data[key] !== "") {
        object[key] = data[key];
      }
      return object;
    }, {});
    return util
      .sendApiRequest("/report/live-session-report/", "POST", true, livesession)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }
}
