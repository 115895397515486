import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import PgService from "../../services/pageService";
import Banner from '../Banner/banner';
import BannerService from "../../services/bannerService";
import { Helmet } from 'react-helmet';

export default  class Pg extends React.Component{

    constructor(props) {
        super(props);  
        this.state = {page:{name:"",title:"",content:""}};
        this.bannerServ = new BannerService();
        this.pgServ = new PgService();
    }
    componentDidMount() {
        this.getPage(this.props.match.params.id);
    }
    componentWillReceiveProps(nextProps) {
        this.getPage(nextProps.match.params.id);
    }

    getPage(name){
        window.scrollTo(0, 0);
        this.pgServ.getPage(name)
            .then(
                (response)=>{
                    this.setState({page: response.data}, () => { 
                        this.getBannerList()
                    });
                },
                (error)=>{
                    this.setState({page: {name:"",title:"",content:""}});
                }
            );
    }
    getBannerList(){
        this.bannerServ.listBanner(0,10000,"pg_" + this.state.page.name,"pagename")
            .then(
                (response)=>{
                  this.bannerList = [];
                  for (let i = 0; i < response.data.length; i++) {
                    this.bannerList.push(response.data[i]);
                  }

                  this.setState({ bannerList: response.data });
                },
                (error)=>{
                    this.setState({bannerList: []});
                }
            );
    }
    render() {
        let page = this.state.page;
        return (
        <>
        <Helmet>
          <title>{page.name}</title>
          <meta name="keywords" content = {page.meta_keywords} />
          <meta name = "description" content = {page.meta_description} />
        </Helmet> 
        <Banner pagename={page.title} pagelink={"/pg/"+page.name} title={page.title} showSearch={false} />
        <div className="about-area default-padding">
            <div className="container">
                <div className="row">
                    <div className="about-info">
                        <div className="col-md-11 info">
                        <h1>{page.title}</h1>
                        <div>{ReactHtmlParser(page.content)}</div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        </> 
        )
    }
}