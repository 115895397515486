import React from "react";
import userservice from "../../services/userService";
import { Redirect } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

export default class ForgetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = { user: "", successMessage: "", errorMessage: "", redirect: false };
    this.userv = new userservice();
  }
  forget_password(e) {
    e.preventDefault();
    this.userv.forget_password(this.state.user).then(
      (response) => {
        alert(
          "You should soon receive an email allowing you to reset your password.Please make sure to check your spam and trash if you can't find the email."
        );
        this.setState({ redirect: true });
      },
      (error) => {
        alert("Sorry you don't have existing account");
        this.setState({ redirect: false });
      }
    );
  }

  handleUsernameChange(event) {
    this.setState({
      user: event.target.value,
    });
  }

  showMessage() {
    if (this.state.successMessage) {
      return <div class="alert alert-success">{this.state.successMessage}</div>;
    }
    if (this.state.errorMessage) {
      return <div class="alert alert-danger">{this.state.errorMessage}</div>;
    }
  }
  render() {
    if (this.state.redirect === true) {
      return <Redirect to="/" />;
    }

    return (
      <div>
        <div className="login-area default-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2">
                <form
                  action="#"
                  id="login-form"
                  className="white-popup-block"
                  onSubmit={this.forget_password.bind(this)}
                >
                  <div className="col-md-8 login-custom col-centered">
                    <h4>Forgot Password!</h4>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="form-group">
                          <label htmlFor="inputUsername" className="sr-only">
                            Email
                          </label>
                          <input
                            type="email"
                            id="inputUsername"
                            className="form-control"
                            placeholder="Email"
                            onChange={this.handleUsernameChange.bind(this)}
                            required
                            autoFocus
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row">
                        <button type="submit">Reset Password</button>

                        <Link title="Lost Password" to={{ pathname: "/login" }} className="lost-pass-link">
                          Login
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
