import React from "react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import OrderService from "../../services/orderService";

export default class OrderDetail extends React.Component {
  constructor(props) {
    super(props);
    this.course_id = props.match.params.id;
    this.user = window.user;
    this.state = { order: "" };
    this.orderServ = new OrderService();
  }
  componentDidMount() {
    this.submitOrder();
  }

  submitOrder(actions) {
    let orderValues = {
      user: this.user.data._id,
      billing_address: this.user.data.address_1,
      order_date: new Date().toISOString().slice(0, 10),
      user_name: this.user.data.first_name + " " + this.user.data.last_name,
      items: [{ course: { _id: this.course_id } }]
    };
    this.setState({
      order: orderValues
    });
    this.orderServ.addOrder(orderValues).then(
      response => {
        this.taxRate = [];
        this.taxType = [];
        this.taxValue = [];
        this.courseName = [];
        let taxdetails = response.data;
        taxdetails.order_date = response.data.order_date
          .split("")
          .splice(0, 10)
          .join("");
        for (let j = 0; j < taxdetails.items.length; j++) {
          for (let i = 0; i < taxdetails.items[j].taxes.length; i++) {
            if (taxdetails.items[j].taxes[i]["tax_type"] === "percentage") {
              this.taxRate.push(
                <div>{taxdetails.items[j].taxes[i]["tax_value"] + "%"}</div>
              );
            } else if (taxdetails.items[j].taxes[i]["tax_type"] === "flat") {
              this.taxRate.push(
                <div>{taxdetails.items[j].taxes[i]["tax_value"]}</div>
              );
            }
          }
        }
        for (let j = 0; j < taxdetails.items.length; j++) {
          for (let i = 0; i < taxdetails.items[j].taxes.length; i++) {
            this.taxType.push(
              <div>{taxdetails.items[j].taxes[i]["tax_name"]}</div>
            );
          }
        }
        for (let j = 0; j < taxdetails.items.length; j++) {
          for (let i = 0; i < taxdetails.items[j].taxes.length; i++) {
            this.taxValue.push(
              <div>{taxdetails.items[j].taxes[i]["value"]}</div>
            );
          }
        }
        for (let j = 0; j < taxdetails.items.length; j++) {
          this.courseName.push(taxdetails.items[j].course.course_name);
        }

        this.setState({ order: response.data });
      },
      error => {
        this.setState({ redirect: false });
      }
    );
  }
  render() {
    return (
      <>
        <div className="date">
          <p>
            <strong>Order Date:</strong>
            {this.state.order.order_date}
          </p>
          <p>
            <strong>Order Id:</strong>
            {this.state.order._id}
          </p>
          <p>
            <strong>Course Name:</strong>
            {this.courseName}
          </p>
          <div>
            <div className="addr">
              <p>
                <strong>Address:</strong>
                {this.state.order.billing_address}
              </p>
            </div>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Base Price</th>
                  <th scope="col">Tax Rate</th>
                  <th scope="col">Tax Type</th>
                  <th scope="col">Tax Value</th>
                  <th scope="col">Final Price</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td scope="col">{1}</td>
                  <td scope="col">
                    <>{process.env.REACT_APP_CURRENCY}</>
                    {this.state.order.base_price}
                  </td>
                  <td scope="col">{this.taxRate}</td>
                  <td scope="col">{this.taxType}</td>
                  <td scope="col">{this.taxValue}</td>
                  <td scope="col">
                    <>{process.env.REACT_APP_CURRENCY}</>
                    {this.state.order.final_price}
                  </td>
                </tr>
                <tr>
                  <td colSpan="5">Total:</td>
                  <td scope="col">
                    <>{process.env.REACT_APP_CURRENCY}</>
                    {this.state.order.final_price}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}
