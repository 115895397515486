import util from '../utils/util';

export default  class PgService{

    getPage(id) {
        return util.sendApiRequest("/pages/pg/"+id,"GET",true).then(
            (response)=>{
                return response;  
            },
            (error)=>{
                throw new Error(error);
            },
        );
    }
}