import React, { Component } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Table,
  Col,
  Row,
  Button,
} from "reactstrap";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import reportService from "../../services/liveSessionService";
import userService from "../../services/userService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import moment from "moment";

const INITIAL_STATE = {
  startDate: new Date(
    moment().startOf("day").subtract(30, "days").utcOffset(0)
  ),
  endDate: new Date(moment().endOf("day").add(30, "days").utcOffset(0)),
};
const getBadge = (status) => {
  return status === "Completed"
    ? "success"
    : status === "Today"
    ? "secondary"
    : status === "Up-coming"
    ? "warning"
    : status === "Today"
    ? "danger"
    : "primary";
};
class LiveSessionReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      liveSession: [],
      startDate: INITIAL_STATE.startDate,
      endDate: INITIAL_STATE.endDate,
      select: {
        value: { value: "", label: "" },
        options: [],
      },
      eventList: [],
      show: false,
    };
    this.reportServ = new reportService();
    this.userServ = new userService();
  }

  componentDidMount() {
    this.getLiveSessionReport();
    this.getUserList();
  }
  handleShow() {
    this.setState({ show: true });
  }
  handleClose() {
    this.setState({ show: false });
  }
  //fetch user/teacher
  getUserList() {
    this.userServ.listUser({}).then(
      (response) => {
        if (!response) {
          return (response = []);
        }
        let teacher = response.data
          .map((el) => {
            if (el.roles.includes("teacher")) {
              return {
                value: el._id,
                label: `${el.first_name} ${el.last_name}`,
              };
            }
          })
          .filter(Boolean);
        this.setState((prevState) => ({
          select: {
            ...prevState.select,
            options: teacher,
            value: null,
          },
        }));
      },
      (error) => {
        toast.error(error);
      }
    );
  }
  getLiveSessionReport() {
    this.reportServ
      .liveSessionReport({
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        teacherId: this.state.select?.value?.value,
        userId: window.user?.data?._id
      })
      .then(
        (response) => {
          if (!response) {
            return (response = []);
          }
          this.setState({ liveSession: response.data });
        },
        (error) => {
          toast.error(error);
        }
      );
  }
  setValue = (value) => {
    this.setState((prevState) => ({
      select: {
        ...prevState.select,
        value,
      },
    }));
  };
  handleClick = () => {
    this.setValue(null);
  };
  handleChange = (value) => {
    this.setValue(value);
  };

  handleChangeStart = (date) => {
    this.setState({
      startDate: date,
    });
  };
  handleChangeEnd = (date) => {
    this.setState({
      endDate: date,
    });
  };
  render() {
    const { select, liveSession, eventList, show } = this.state;
    let isTeacher =
      window.user?.data?.roles?.includes("teacher") &&
      window?.user?.data?.roles?.length === 1;
    return (
      <>
        <Card>
          <CardHeader>
            {/* <i className="fa fa-align-justify"></i>Live Session Report */}
            <Row>
              <Col sm={2}>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={this.state.startDate}
                  onChange={this.handleChangeStart}
                  placeholderText="Start Date"
                />
              </Col>
              <Col sm={2}>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={this.state.endDate}
                  onChange={this.handleChangeEnd}
                  placeholderText="End Date"
                />
              </Col>
              {!isTeacher && (
                <Col sm={3}>
                  <Select
                    options={select.options}
                    placeholder="Filter Teacher"
                    onChange={this.handleChange}
                    value={select.value}
                  />
                </Col>
              )}
              <Col sm={1}>
                <Button
                  onClick={() => {
                    this.getLiveSessionReport();
                  }}
                  color="primary"
                >
                  Filter
                </Button>
              </Col>
              <Col sm={2}>
                <Button
                  onClick={() => {
                    this.setState(
                      {
                        endDate: INITIAL_STATE.endDate,
                        startDate: INITIAL_STATE.startDate,
                      },
                      () => {
                        this.handleClick();
                      }
                    );
                    this.setState(
                      (prevState) => ({
                        select: {
                          ...prevState.select,
                          value: null,
                        },
                      }),
                      () => {
                        this.getLiveSessionReport();
                      }
                    );
                  }}
                  color="warning"
                >
                  Reset Filters
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <CardHeader>Live Session Events Detail's</CardHeader>
            <Table hover bordered striped size="sm" style={{ height: "400px", overflow: "auto" }}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Course Name</th>
                  <th>Event</th>
                  <th>Teacher Name</th>
                </tr>
              </thead>
              <tbody>
                {liveSession.map((el, i) => {
                  return (
                    <tr key={el._id}>
                      <td>{i + 1}</td>
                      <td>
                        <Link
                          to="/user/profile"
                          onClick={(e) => {
                            this.setState({ eventList: el.eventDates }, () => {
                              
                            });
                          }}
                        >
                          {" "}
                          {el?.name}
                        </Link>
                      </td>
                      <td>
                      <div className = 'scrollable-table-user'>
                        {el.eventDates.length ? (
                          <Table responsive hover bordered striped size="sm"  style={{ height: "400px", overflow: "auto" }}>
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Date</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {el.eventDates.map((el, i) => {
                                let status;
                                if (moment().isSame(el.momentDate, "day")) {
                                  status = "Today";
                                } else if (
                                  moment().isBefore(el.momentDate, "day")
                                ) {
                                  status = "Up-coming";
                                } 
                                else if (
                                  moment().isAfter(el.momentDate, "day")
                                ) {
                                  status = "Completed";
                                }
                                return (
                                  <tr key={el.momentDate}>
                                    <td>{i + 1}</td>
                                    <td>{`${moment(el.momentDate).format(
                                      "LL"
                                    )} at ${moment(el.start_time).format(
                                      "LT"
                                    )} to ${moment(el.end_time).format(
                                      "LT"
                                    )} `}</td>
                                    <td>
                                      {" "}
                                      <div className = 'badge-width'>
                                      <Badge className = {getBadge(status)}>
                                          {/* color={getBadge(status)} */}
                                        {status}
                                      </Badge>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        ) : (
                          <div style={{ textAlign: "center" }}>
                            No Event Found
                          </div>
                        )}
                        </div>
                      </td>
                      <td>{el.teacherName}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </CardBody>

          <ToastContainer />
        </Card>
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          onHide={() => {
            this.handleClose();
          }}
          size="lg"
        >
          <Modal.Header closeButton>
          Event List
          </Modal.Header>
          <Modal.Body>
            {eventList.length ? (
              <Table hover bordered striped size="sm">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {eventList.map((el, i) => {
                    let status;
                    if (moment().isSame(el.momentDate, "day")) {
                      status = "Today";
                    } else if (moment().isBefore(el.momentDate, "day")) {
                      status = "Up-coming";
                    } else if (moment().isAfter(el.momentDate, "day")) {
                      status = "Completed";
                    }
                    return (
                      <tr key={el.momentDate}>
                        <td>{i + 1}</td>
                        <td>{`${moment(el.momentDate).format("LL")} at ${moment(
                          el.start_time
                        ).format("LT")} to ${moment(el.end_time).format(
                          "LT"
                        )} `}</td>
                        <td>
                          {" "}
                          <div className = 'badge-width'>
                            <Badge className = {getBadge(status)}>
                                {status}
                            </Badge>
                        </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              <div style={{ textAlign: "center" }}>No Event Found</div>
            )}
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default LiveSessionReport;