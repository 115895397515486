import React from "react";
import userservice from "../../services/userService";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Redirect } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Formik, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import countryList from "../../components/countryList.json";

export default class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.stud_id = props.match.params.id;
    this.state = { student: null, redirect: false, error: "", successmessage: "" };
    this.state.student = {
      title: "Mr",
      first_name: "",
      last_name: "",
      email: "",
      address_1: "",
      address_2: "",
      address_3: "",
      state: "",
      country: "",
      city: "",
      zipcode: "",
      phone: "",
      recieve_newsletter: false,
      receive_personalised: false,
      is_active: true,
      courses: [],
      roles: ["student"],
      password: "",
      dob: "",
      gender: "",
      race: "",
      ethnicity: "",
      marital_status: "",
      income: "",
      education: "",
      employment: "",
      industry: "",
    };
    const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{5}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)?$/;
    this.studserv = new userservice();
    this.schema = Yup.object({
      title: Yup.string(),
      first_name: Yup.string().required("first name is a required field").max(100, "Too Long"),
      last_name: Yup.string().required("last name is a required field").max(100, "Too Long"),
      email: Yup.string().required("email is a required field").email(),
      address_1: Yup.string().required("address 1 is a required field").min(5, "Too short").max(100, "Too Long"),
      address_2: Yup.string().min(5, "Too short").max(100, "Too Long"),
      address_3: Yup.string().min(5, "Too short").max(100, "Too Long"),
      country: Yup.string().required("country is a required field").max(100, "Too Long"),
      state: Yup.string().required("state is a required field"),
      city: Yup.string(),
      passsword: Yup.string(),
      zipcode: Yup.string().required("zipcode is a required field"),
      phone: Yup.string().required("phone number is a required field").matches(phoneRegExp, "invalid phone number"),
      recieve_newsletter: Yup.boolean(),
      receive_personalised: Yup.boolean(),
      is_active: Yup.boolean(),
      password: Yup.string().required("password is a required field"),
      confirmPassword: Yup.string().oneOf([Yup.ref("password"), null], "password must match"),
      dob: Yup.date(),
      gender: Yup.string(),
      race: Yup.string(),
      ethnicity: Yup.string(),
      marital_status: Yup.string(),
      income: Yup.string(),
      education: Yup.string(),
      employment: Yup.string(),
      industry: Yup.string(),
    });
  }
  submitStudentForm(values, actions) {
    actions.setSubmitting(false);
    this.setState({
      student: values,
    });
    if (this.stud_id) {
      this.state.student.roles = [];
      if (values.teacher === true) {
        values.teacher = "teacher";
        this.state.student.roles.push("teacher");
      } else if (values.teacher === false) {
        delete values.teacher;
      }
      if (values.admin === true) {
        values.admin = "admin";
        this.state.student.roles.push("admin");
      } else if (values.admin === false) {
        delete values.admin;
      }
      if (values.manager === true) {
        values.manager = "manager";
        this.state.student.roles.push("manager");
      } else if (values.manager === false) {
        delete values.manager;
      }
      if (values.student === true) {
        values.student = "student";
        this.state.student.roles.push("student");
      } else if (values.student === false) {
        delete values.student;
      }
      delete values.student;
      delete values.manager;
      delete values.teacher;
      delete values.admin;
      this.studserv.editUser(this.state.student).then(
        (response) => {
          this.setState({ redirect: true });
        },
        (error) => {
          this.setState({ redirect: false });
        }
      );
    } else {
      if (values.teacher === true) {
        values.teacher = "teacher";
        this.state.student.roles.push("teacher");
      }
      if (values.admin === true) {
        values.admin = "admin";
        this.state.student.roles.push("admin");
      }
      if (values.manager === true) {
        values.manager = "manager";
        this.state.student.roles.push("manager");
      }
      if (values.student === true) {
        values.student = "student";
        this.state.student.roles.push("student");
      }

      delete values.student;
      delete values.manager;
      delete values.teacher;
      delete values.admin;
      this.studserv.addUser(this.state.student).then(
        (response) => {
          this.setState({
            redirect: true,
            successmessage: "Registration Successful, Login With Your Registered Account",
            error: "",
          });
        },
        (error) => {

          this.setState({ redirect: false, error: error });
        }
      );
    }
  }

  render() {
    if (this.state.redirect === true) {
      alert(this.state.successmessage);
      return <Redirect to="/login" />;
    }
    let countryOption = [];
    for (let i = 0; i < countryList.length; i++) {
      countryOption.push(
        <option key={countryList[i].Code + " " + i} value={countryList[i].Name}>
          {countryList[i].Name}
        </option>
      );
    }
    return (
      <Formik
        validationSchema={this.schema}
        initialValues={this.state.student}
        enableReinitialize={true}
        onSubmit={this.submitStudentForm.bind(this)}
        render={({ values, errors, status, touched, handleBlur, handleChange, handleSubmit, isSubmitting }) => (
          <div>
            <div className="login-area default-padding">
              <div className="container">
                <div className="row">
                  <div className="col-md-10 col-md-offset-1">
                    <form action="#" id="register-form" className="white-popup-block" onSubmit={handleSubmit}>
                      <div className="col-md-18 login-custom">
                        <h4>Register a new account</h4>
                        <Row>
                          <Col sm={12} md={6}>
                            <Form.Label>Title*</Form.Label>
                            <Form.Control
                              className="dropdownform"
                              as="select"
                              name="title"
                              value={values.title}
                              onChange={handleChange}
                              isValid={touched.title && !errors.title}
                            >
                              <option value="Dr">Dr</option>
                              <option value="Mr">Mr</option>
                              <option value="Mrs">Mrs</option>
                              <option value="Miss">Miss</option>
                              <option value="Ms">Ms</option>
                            </Form.Control>
                          </Col>

                          <Col sm={10} md={6}>
                            <Form.Group>
                              <Form.Label>Email*</Form.Label>
                              <Form.Control
                                type="text"
                                value={values.email}
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.email && !errors.email}
                              />
                              <ErrorMessage name="email">
                                {(msg) => <div className="err_below">{msg}</div>}
                              </ErrorMessage>
                              {"This Email Is Already Registered" === this.state.error ? (
                                <div className="errormsg">{"email is already registered"}</div>
                              ) : (
                                  " "
                                )}
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} md={6}>
                            <Form.Group>
                              <Form.Label>First Name*</Form.Label>
                              <Form.Control
                                type="text"
                                value={values.first_name}
                                name="first_name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.first_name && !errors.first_name}
                              />
                              <ErrorMessage name="first_name">
                                {(msg) => <div className="err_below">{msg}</div>}
                              </ErrorMessage>
                            </Form.Group>
                          </Col>
                          <Col sm={12} md={6}>
                            <Form.Group>
                              <Form.Label>Last Name*</Form.Label>
                              <Form.Control
                                type="text"
                                value={values.last_name}
                                name="last_name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.last_name && !errors.last_name}
                              />
                              <ErrorMessage name="last_name">
                                {(msg) => <div className="err_below">{msg}</div>}
                              </ErrorMessage>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} md={6}>
                            <Form.Group>
                              <Form.Label>Address Line 1*</Form.Label>
                              <Form.Control
                                type="text"
                                value={values.address_1}
                                name="address_1"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <ErrorMessage name="address_1">
                                {(msg) => <div className="err_below">{msg}</div>}
                              </ErrorMessage>
                            </Form.Group>
                          </Col>
                          <Col sm={12} md={6}>
                            <Form.Group>
                              <Form.Label>Address 2 </Form.Label>
                              <Form.Control
                                type="text"
                                value={values.address_2}
                                name="address_2"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <ErrorMessage name="address_2">
                                {(msg) => <div className="err_below">{msg}</div>}
                              </ErrorMessage>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} md={6}>
                            <Form.Group>
                              <Form.Label>Address 3 </Form.Label>
                              <Form.Control
                                type="text"
                                value={values.address_3}
                                name="address_3"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.address_3 && !errors.address_3}
                              />
                              <ErrorMessage name="address_3">
                                {(msg) => <div className="err_below">{msg}</div>}
                              </ErrorMessage>
                            </Form.Group>
                          </Col>
                          <Col sm={12} md={6}>
                            <Form.Group>
                              <Form.Label>City</Form.Label>
                              <Form.Control
                                type="text"
                                value={values.city}
                                name="city"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.city && !errors.city}
                              />
                              <ErrorMessage name="city">{(msg) => <div className="err_below">{msg}</div>}</ErrorMessage>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} md={6}>
                            <Form.Group>
                              <Form.Label>ZipCode*</Form.Label>
                              <Form.Control
                                type="text"
                                value={values.zipcode}
                                name="zipcode"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.zipcode && !errors.zipcode}
                              />
                              <ErrorMessage name="zipcode">
                                {(msg) => <div className="err_below">{msg}</div>}
                              </ErrorMessage>
                            </Form.Group>
                          </Col>
                          <Col sm={12} md={6}>
                            <Form.Group>
                              <Form.Label>State*</Form.Label>
                              <Form.Control
                                type="text"
                                value={values.state}
                                name="state"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.state && !errors.state}
                              />
                              <ErrorMessage name="state">
                                {(msg) => <div className="err_below">{msg}</div>}
                              </ErrorMessage>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} md={6}>
                            <Form.Group>
                              <Form.Label>Country*</Form.Label>
                              <Form.Control
                                className="dropdownform"
                                as="select"
                                name="country"
                                value={values.country}
                                onChange={handleChange}
                              >
                                <option></option>
                                {countryOption}
                              </Form.Control>
                              <ErrorMessage name="country">
                                {(msg) => <div className="err_below">{msg}</div>}
                              </ErrorMessage>
                            </Form.Group>
                          </Col>
                          <Col sm={12} md={6}>
                            <Form.Group>
                              <Form.Label>Date Of Birth</Form.Label>
                              <Form.Control
                                type="date"
                                value={values.dob}
                                name="dob"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.dob && !errors.dob}
                              />
                              <ErrorMessage name="dob">{(msg) => <div className="err_below">{msg}</div>}</ErrorMessage>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Col sm={12} md={6}>
                              <Form.Label>Gender</Form.Label>
                              <Form.Control
                                className="dropdownform"
                                as="select"
                                name="gender"
                                value={values.gender}
                                onChange={handleChange}
                                isValid={touched.gender && !errors.gender}
                              >
                                <option value=""></option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="other">Other</option>
                              </Form.Control>
                            </Col>

                            <ErrorMessage name="gender">{(msg) => <div className="err_below">{msg}</div>}</ErrorMessage>
                          </Col>

                          <Col sm={12} md={6}>
                            <Form.Label>Race</Form.Label>
                            <Form.Control
                              className="dropdownform"
                              as="select"
                              name="race"
                              value={values.race}
                              onChange={handleChange}
                              isValid={touched.race && !errors.race}
                            >
                              <option value="">Select</option>
                              <option value="hinduism">Hinduism</option>
                              <option value="islam">Islam</option>
                              <option value="buddhism">Buddhism</option>
                              <option value="christianity">Christianity</option>
                              <option value="other">Other</option>
                            </Form.Control>
                          </Col>
                        </Row>
                        <Row style={{ paddingBottom: "13px" }}>
                          <Col sm={12} md={6}>
                            <Form.Label>Income</Form.Label>
                            <Form.Control
                              className="dropdownform"
                              as="select"
                              name="income"
                              value={values.income}
                              onChange={handleChange}
                              isValid={touched.income && !errors.income}
                            >
                              <option value="">Select</option>
                              <option value="below_1lakh">below 1 lakh</option>
                              <option value="2_lakh">2 lakh</option>
                              <option value="3_lakh">3 lakh </option>
                              <option value="4_lakh">4 lakh</option>
                              <option value="above_5lakh"> above 5 lakh</option>
                            </Form.Control>

                            <ErrorMessage name="income">{(msg) => <div className="err_below">{msg}</div>}</ErrorMessage>
                          </Col>
                          <Col sm={12} md={6}>
                            <Form.Label>Marital Status</Form.Label>
                            <Form.Control
                              className="dropdownform"
                              as="select"
                              name="marital_status"
                              value={values.marital_status}
                              onChange={handleChange}
                              isValid={touched.marital_status && !errors.marital_status}
                            >
                              <option value="">Select</option>
                              <option value="single">Single</option>
                              <option value="married">Married</option>
                              <option value="widowed">Widowed</option>
                              <option value="separated">Separated</option>
                              <option value="divorced">Divorced</option>
                            </Form.Control>

                            <ErrorMessage name="marital_status">
                              {(msg) => <div className="err_below">{msg}</div>}
                            </ErrorMessage>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} md={6}>
                            <Form.Group>
                              <Form.Label>Education</Form.Label>
                              <Form.Control
                                type="text"
                                value={values.education}
                                name="education"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <ErrorMessage name="education">
                                {(msg) => <div className="err_below">{msg}</div>}
                              </ErrorMessage>
                            </Form.Group>
                          </Col>
                          <Col sm={12} md={6}>
                            <Form.Label>Industry</Form.Label>
                            <Form.Control
                              type="text"
                              name="industry"
                              value={values.industry}
                              onChange={handleChange}
                              isValid={touched.industry && !errors.industry}
                            >

                            </Form.Control>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} md={6}>
                            <Form.Group>
                              <Form.Label>Employment</Form.Label>
                              <Form.Control
                                type="text"
                                value={values.employment}
                                name="employment"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <ErrorMessage name="employment">
                                {(msg) => <div className="err_below">{msg}</div>}
                              </ErrorMessage>
                            </Form.Group>
                          </Col>
                          <Col sm={12} md={6}>
                            <Form.Group>
                              <Form.Label>Ethnicity</Form.Label>
                              <Form.Control
                                type="text"
                                value={values.ethnicity}
                                name="ethnicity"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <ErrorMessage name="ethnicity">
                                {(msg) => <div className="err_below">{msg}</div>}
                              </ErrorMessage>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm={12} md={6}>
                            <Form.Group>
                              <Form.Label>Phone*</Form.Label>
                              <Form.Control
                                type="text"
                                autoComplete="new-phone"
                                name="phone"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.phone && !errors.phone}
                              />
                              <ErrorMessage name="phone">
                                {(msg) => <div className="err_below">{msg}</div>}
                              </ErrorMessage>
                            </Form.Group>
                          </Col>
                          <Col sm={12} md={6}>
                            <Form.Group>
                              <Form.Label>Password*</Form.Label>
                              <Form.Control
                                type="password"
                                name="password"
                                autoComplete="new-password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.password && !errors.password}
                              />
                              <ErrorMessage name="password">
                                {(msg) => <div className="err_below">{msg}</div>}
                              </ErrorMessage>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} md={6}>
                            <Form.Group>
                              <Form.Label>Confirm Password*</Form.Label>
                              <Form.Control
                                type="password"
                                name="confirmPassword"
                                autoComplete="new-password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.confirmPassword && !errors.confirmPassword}
                              />
                              <ErrorMessage name="confirmPassword">
                                {(msg) => <div className="err_below">{msg}</div>}
                              </ErrorMessage>
                              {`confirm password is a required` === this.state.error ? (
                                <div className="errormsg">{"Confirm Password is a required"}</div>
                              ) : (
                                  " "
                                )}
                            </Form.Group>
                          </Col>
                        </Row>

                        <div className="rcvnws">
                          <strong>Receive Personalised </strong>
                        </div>
                        <Row>
                          <Col sm={12} md={12}>
                            <Form.Group controlId="form20" className="form20rcvper">
                              <Form.Check
                                type="checkbox"
                                value={values.receive_personalised}
                                name="receive_personalised"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.receive_personalised && !errors.receive_personalised}
                              />
                              <ErrorMessage name="receive_personalised">
                                {(msg) => <div className="err_below">{msg}</div>}
                              </ErrorMessage>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} md={12}>
                            <div className="rcvnws">
                              <strong>Receive NewsLetter</strong>
                            </div>
                            <Form.Group controlId="form20" className="form20rcvnws">
                              <Form.Check
                                type="checkbox"
                                value={values.recieve_newsletter}
                                name="recieve_newsletter"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.recieve_newsletter && !errors.recieve_newsletter}
                              />
                              <ErrorMessage name="recieve_newsletter">
                                {(msg) => <div className="err_below">{msg}</div>}
                              </ErrorMessage>
                            </Form.Group>
                          </Col>
                        </Row>
                        <div className="col-md-12">
                          <div className="row">
                            <button type="submit">Sign up</button>
                          </div>
                        </div>
                        <p className="link-bottom">
                          Are you a member? <Link to={{ pathname: "/login" }}>Login now</Link>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      />
    );
  }
}
