import React from "react";
import { Link } from "react-router-dom";
import Setting from "../../services/settingService";
import { copyFile } from "fs";
export default class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { settingList: [] };

    this.settingServ = new Setting();
  }

  componentDidMount() {
    this.settingfn();
  }
  settingfn() {
    this.settingServ.listSetting(0, 1000000).then(
      (response) => {
        this.setState({ settingList: response.data });
      },
      (error) => {
        this.setState({ settingList: [] });
      }
    );
  }

  render() {
    let data = [];
    let copyright = "",
      email = "",
      facebooklink = "",
      googlelink = "",
      linkedinlink = "",
      officeaddr = "",
      twitterlink = "",
      youtubelink = "",
      copyrighturl = "",
      logo = "";
    if (this.state.settingList) {
      for (let i = 0; i < this.state.settingList.length; i++) {
        if (this.state.settingList[i].name === "copyright") {
          copyright = this.state.settingList[i].value;
        }
        if (this.state.settingList[i].name === "Email") {
          email = this.state.settingList[i].value;
        }
        if (this.state.settingList[i].name === "facebookLink") {
          facebooklink = this.state.settingList[i].value;
        }
        if (this.state.settingList[i].name === "googlePlusLink") {
          googlelink = this.state.settingList[i].value;
        }
        if (this.state.settingList[i].name === "linkedinLink") {
          linkedinlink = this.state.settingList[i].value;
        }
        if (this.state.settingList[i].name === "office address") {
          officeaddr = this.state.settingList[i].value;
        }
        if (this.state.settingList[i].name === "twitterLink") {
          twitterlink = this.state.settingList[i].value;
        }
        if (this.state.settingList[i].name === "youtubeLink") {
          youtubelink = this.state.settingList[i].value;
        }
        if (this.state.settingList[i].name === "copyrightUrl") {
          copyrighturl = this.state.settingList[i].value;
        }
        if (this.state.settingList[i].name === "logo") {
          logo = this.state.settingList[i].value;
        }
      }
    }
    return (
      <footer className="bg-dark footer-padding text-light">
        <div className="container">
          <div className="row">
            <div className="f-items">
              <div className="col-md-3 item">
                <div className="f-item">
                  <img src={logo} alt="Logo" style={{ height: "100px", maxWidth: "72%", backgroundColor: "white" }} />
                  <p>
                    &copy; Copyright 2019. All Rights Reserved by <a href={copyrighturl}>{copyright}</a>
                  </p>
                  <div className="social">
                    <ul>
                      <li>
                        <a target="_Blank" href={facebooklink}>
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a target="_Blank" href={twitterlink}>
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a target="_Blank" href={googlelink}>
                          <i className="fab fa-google-plus-g"></i>
                        </a>
                      </li>
                      <li>
                        <a target="_Blank" href={youtubelink}>
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                      <li>
                        <a target="_Blank" href={linkedinlink}>
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                      <li>
                        <a href="/news/rss">
                          <i className="fas fa-rss"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-8 item">
                <div className="f-item link">
                  <h4>Links</h4>
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/pg/disclaimer_copyright">Disclaimers & Copyright </Link>
                    </li>
                    <li>
                      <Link to="/pg/about_us">About Us</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-3 col-sm-6 item">
                <div className="f-item link">
                  <h4>Links</h4>
                  <ul>
                    <li>
                      <Link to="/pg/terms_conditions">Terms And Condition</Link>
                    </li>
                    <li>
                      <Link to="/pg/privacy_policy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to={{ pathname: "/faq" }}>FAQs</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-2 item">
                <div className="f-item recent-post">
                  <h4>Address</h4>
                  <ul>
                    <li>
                      <p>
                        <i className="fas fa-envelope mg-right-foot"></i>
                        Email{" "}
                        <span>
                          <a href={`mailto:${email}`} target="_blank">
                            {email}
                          </a>
                        </span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <i className="fas fa-map  mg-right-foot"></i>
                        Office <span>{officeaddr}</span>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
