import React from "react";

function resultScreen(props) {
  return (
    <div className="result">
      You got {props.marksObtained} out of {props.totalMarks} in <strong>{props.quizzeName} </strong>test
    </div>
  );
}

export default resultScreen;
