import util from "../utils/util";

export default class QuizeeService {
  SubmitQuizze(data) {
    return util.sendApiRequest("/quizze/submit", "POST", true, data).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }
  //to check the quizz if user submitted or not
  getSubmittedQuizze(userId, quizzeId) {
    return util.sendApiRequest("/qresult/" + userId + "/" + quizzeId, "GET", true).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }
  //get quizzes question
  getQuizzeQuestion(courseId, lessonId, quizeId) {
    return util.sendApiRequest("/quizze/frontend/" + courseId + "/" + lessonId + "/" + quizeId, "GET", true).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }
  //reset the quizze result
  resetQuizzeResult(quizeId) {
    return util.sendApiRequest("/quizze/frontend/reset/" + quizeId, "GET", true).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }
  //get the course result and completed quizze  of user
  courseResult(courseId) {
    return util.sendApiRequest("/quizze/frontend/result/" + courseId, "GET", true).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }
}
