import util from "../utils/util";

export default class UserService {
  getCrsResult() {
    return util.sendApiRequest("/lms", "GET", true).then(
      response => {
        return response;
      },
      error => {
        throw new Error(error);
      }
    );
  }
}
