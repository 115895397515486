import React from "react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import OrderService from "../../services/orderService";
import Invoice from "../../services/invoiceGenerator.service";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import User from "../../services/userService";
import moment from "moment";
import setting from "../../services/settingService";

export default class PreviewOrder extends React.Component {
  constructor(props) {
    super(props);
    this.token = props.match.params.token;
    this.course_id = props.match.params.id;
    this.user = window.user;
    this.state = {
      order: "",
      uservalue:"",
      message: "",
      messageType: "",
      sucessMessage: "",
      errorMessage: "",
      error: "",
      settingList:[]
    };

    this.orderServ = new OrderService();
    this.invoiceServ = new Invoice();
    this.userserv = new  User();
    this.settingserv = new setting();
  }
  componentDidMount() {
    this.submitOrder();
    this.getuserdata();
    window.scrollTo(0, 0);
    this.getSettingVal();
  }
  getSettingVal(){
    this.settingserv.listSetting(0,10000000).then(
      (response) => {
        this.setState({ settingList: response.data });
      },
      (error)=>{
        this.setState({ settingList: [] });
      }
    )
  }
  processPayment() {
    let thiis = this;
    let options = {
      key: "rzp_test_AAqBBKEd4sOmOa",
      amount:parseInt(this.state.order.final_price*100), // to convert it into paise
      name: "M/s RCBuminiaga,Publishing Clinic",
      description: "Couse purchased",
      image: "http://pclinic.ritzyware.net/assets/img/RCBuminiaga Logo.png",
      handler: function(response) {
        thiis.updateOrderStatus(response.razorpay_payment_id);
      },
      modal: {
        ondismiss: function(response) {
          thiis.updateOrderStatus(response);
        }
      },
      prefill: {
        name: this.state.order.user_name,
        email:this.state.uservalue.email,
        contact:this.state.uservalue.phone
      },
      theme: {
        color: "#F37254"
      }
    };
    let rzp = new window.Razorpay(options);
    rzp.open();
  }

  getuserdata(){
    if(this.user){
      this.user.data._id = this.user.data._id;
      this.userserv.getUser(this.user.data._id).then(
        (response)=>{
          this.setState({
            uservalue:response.data
          })
        },
        (error) => {
          this.setState({
            uservalue:""
          });
        }
      )
    }else if(this.token){
      this.orderServ.getOrderByToken(this.token).then(
        (response)=>{
          this.setState({
            uservalue:response.data.user
          })
        },
        (error) => {
          this.setState({
            uservalue:""
          });
        }
      )
    }
    
  }
  saveOrder() {
    let Useraddr = "";
    let course_id = "";
    if(this.user){
      course_id = this.course_id;
      Useraddr = this.user.data.address_1 + "\n";
      if(this.user.data.address_2){
        Useraddr = Useraddr + this.user.data.address_2+ "\n";
      }
      if(this.user.data.address_3){
        Useraddr = Useraddr + this.user.data.address_3 + "\n";
      }
      if(this.user.data.town){
        Useraddr = Useraddr + this.user.data.town+ "\n";
      }
      if(this.user.data.city){
        Useraddr = Useraddr + this.user.data.city+ "\n";
      }
        Useraddr = Useraddr + this.user.data.state+ " "+this.user.data.country+"\n";
      
      if(this.user.data.zipcode){          
        Useraddr = Useraddr + this.user.data.zipcode
      }
    }
    else{
      Useraddr = this.state.uservalue.address_1 + "\n";
      if(this.state.uservalue.address_2){
        Useraddr = Useraddr + this.state.uservalue.address_2+ "\n";
      }
      if(this.state.uservalue.address_3){
        Useraddr = Useraddr + this.state.uservalue.address_3 + "\n";
      }
      if(this.state.uservalue.town){
        Useraddr = Useraddr + this.state.uservalue.town+ "\n";
      }
      if(this.state.uservalue.city){
        Useraddr = Useraddr + this.state.uservalue.city+ "\n";
      }
        Useraddr = Useraddr + this.state.uservalue.state+ " "+this.state.uservalue.country+"\n";
      
      if(this.state.uservalue.zipcode){          
        Useraddr = Useraddr + this.state.uservalue.zipcode
      }
      course_id = this.state.order.items[0].course._id;
    }
    let orderValues = {
      copoun: this.state.copoun,
      user: this.token?this.state.uservalue._id:this.user.data._id,
      billing_address:Useraddr,
      payment_channel:"Online",
      order_date: new Date().toISOString().slice(0, 10),
      
      items: [{ course: { _id: course_id } }]
    };
    this.orderServ.addOrder(orderValues).then(
      async response => {
        response.data.order_date = response.data.order_date
          .split("")
          .splice(0, 10)
          .join("");
        if (response.data && response.data._id) {
          await this.setState({
            order: response.data
          });
          this.setState({error:""});
          this.processPayment();
        } else {
          this.setState({
            messageType: "danger",
            message: "Something went wrong, please try again or contact admin."
          });
        }
      },
      error => {
        this.setState({
          successMessage: "",
          errorMessage: "Something went wrong, please try again.",
          error: error.toString()
        });
      }
    );
  }

  updateOrderStatus(paym_id) {
    let order_id = this.state.order._id;
    if (paym_id) {
      let paymentUpdate = {
        paym_id: paym_id,
        order_id: order_id,
        copoun: this.state.copoun
      };
      this.orderServ.paymentCapture(paymentUpdate).then(
        response => {
          if (response.data._id && response.data) {
            this.invoiceServ.getInvoice(response.data._id).then();
          }
          this.setState({
            successMessage:
              "Your order is successfully placed with your order id:",
            errorMessage: ""
            
          });
        },
        error => {
          this.setState({
            successMessage: "",
            errorMessage:
              "Something went wrong, please try again or contact admin."
          });
        }
      );
    } else {
      this.orderServ.paymentCancelled(order_id).then(response => {});
    }
  }

  submitOrder(actions) {
    if(this.token){
      this.orderServ.getOrderByToken(this.token).then(
        response => {
          let course_id = response.data.items.map((id)=>id.course._id)
          let Useraddr = "";
          Useraddr = response.data.user.address_1 + "\n";
          if(response.data.user.address_2){
            Useraddr = Useraddr + response.data.user.address_2+ "\n";
          }
          if(response.data.user.address_3){
            Useraddr = Useraddr + response.data.user.address_3 + "\n";
          }
          if(response.data.user.town){
            Useraddr = Useraddr + response.data.user.town+ "\n";
          }
          if(response.data.user.city){
            Useraddr = Useraddr + response.data.user.city+ "\n";
          }
          Useraddr = Useraddr + response.data.user.state+ " " 
                    + response.data.user.country+ "\n";
          if(response.data.user.zipcode){          
            Useraddr = Useraddr + response.data.user.zipcode
          }
          let orderValues = {
            copoun: this.state.copoun,
            user: response.data.user._id,
            billing_address: Useraddr,
            payment_channel:"Online",
            order_date:moment(response.data.order_date).format("YYYY-MM-DD"),
            user_name: response.data.user.first_name + " " + response.data.user.last_name,
            items: [{ course: { _id: course_id[0] } }]
          };
          this.setState({
            order: orderValues
          });
          this.fillData(response.data);
          this.processPayment();
        },
        error => {
          this.setState({ redirect: false, error: error.toString() });
        }
      );
    }
    else{
      let Useraddr = "";
      Useraddr = this.user.data.address_1 + "\n";
      if(this.user.data.address_2){
        Useraddr = Useraddr + this.user.data.address_2+ "\n";
      }
      if(this.user.data.address_3){
        Useraddr = Useraddr + this.user.data.address_3 + "\n";
      }
      if(this.user.data.town){
        Useraddr = Useraddr + this.user.data.town+ "\n";
      }
      if(this.user.data.city){
        Useraddr = Useraddr + this.user.data.city+ "\n";
      }
      Useraddr = Useraddr + this.user.data.state+ " " 
                + this.user.data.country+ "\n";
      if(this.user.data.zipcode){          
        Useraddr = Useraddr + this.user.data.zipcode
      }
      let orderValues = {
        copoun: this.state.copoun,
        user: this.user.data._id,
        billing_address: Useraddr,
        payment_channel:"Online",
        order_date: new Date().toISOString().slice(0, 10),
        user_name: this.user.data.first_name + " " + this.user.data.last_name,
        items: [{ course: { _id: this.course_id } }]
      };
     
      
      this.orderServ.previewOrder(orderValues).then(
        response => {
          this.fillData(response.data);
          this.setState({error : ""})
        },
        error => {
          this.setState({ redirect: false, error: error.toString() });
        }
      );
    }
  }

  fillData(ordData){
    this.taxRate = [];
    this.taxType = [];
    this.taxValue = [];
    this.courseName = [];

    let taxdetails = ordData;
    taxdetails.order_date = ordData.order_date
      .split("")
      .splice(0, 10)
      .join("");

    for (let j = 0; j < taxdetails.items.length; j++) {
      for (let i = 0; i < taxdetails.items[j].taxes.length; i++) {
        if (taxdetails.items[j].taxes[i]["tax_type"] === "percentage") {
          this.taxRate.push(
            <div key={"taxrate" + i + "perc"}>
              {taxdetails.items[j].taxes[i]["tax_value"] + "%"}
            </div>
          );
        } else if (taxdetails.items[j].taxes[i]["tax_type"] === "flat") {
          this.taxRate.push(
            <div key={"taxrate" + i + "flat"}>
              {taxdetails.items[j].taxes[i]["tax_value"]}
            </div>
          );
        }
      }
    }
    for (let j = 0; j < taxdetails.items.length; j++) {
      for (let i = 0; i < taxdetails.items[j].taxes.length; i++) {
        this.taxType.push(
          <div key={"taxtype" + i + "_" + j}>
            {taxdetails.items[j].taxes[i]["tax_name"]}
          </div>
        );
      }
    }
    for (let j = 0; j < taxdetails.items.length; j++) {
      for (let i = 0; i < taxdetails.items[j].taxes.length; i++) {
        this.taxValue.push(
          <div key={"taxdetail" + i + "_" + j}>
            {taxdetails.items[j].taxes[i]["value"]}
          </div>
        );
      }
    }
    for (let j = 0; j < taxdetails.items.length; j++) {
      this.courseName.push(taxdetails.items[j].course.course_name);
    }
    this.setState({ order: ordData });
  }

  showMessage() {
    if (this.state.successMessage) {
      return (
        <div className="alert alert-success">
          {this.state.successMessage}
          {this.state.order._id}
        </div>
      );
    }
    if (this.state.errorMessage) {
      return (
        <div className="alert alert-danger">{this.state.errorMessage}</div>
      );
    }
  }
  handlecopoun(event) {
    this.setState({
      copoun: event.target.value
    });
  }
  render() {
    let currencyVal = "";
    for (let i = 0; i<this.state.settingList.length; i++){
    if(this.state.settingList[i].name === 'currencySymbol'){
        currencyVal = this.state.settingList[i].value;
      }
    }
    let useraddr1 = (this.state.uservalue.address_1 === "")? "":this.state.uservalue.address_1
    let useraddr2 = (this.state.uservalue.address_2 === "")? "":this.state.uservalue.address_2
    let useraddr3 = (this.state.uservalue.address_3 === "")? "":this.state.uservalue.address_3
    return (
      <>
        {this.showMessage()}

        <div className="date">
          <p className="frontendorder">
            {useraddr1 ? useraddr1 : null}
            {useraddr2 && <br />}
            {useraddr2 ? useraddr2 : ""}
            {useraddr3 && <br />}
            {useraddr3 ? useraddr3 : null}
            <br />
            {this.state.uservalue.town ? this.state.uservalue.town : null}
            {this.state.uservalue.town && <br />}
            {this.state.uservalue.city}
            <br />
            {this.state.uservalue.state}&nbsp;{this.state.uservalue.country}
            <br />
            {this.state.uservalue.zipcode}
          </p>
          <div>
            <div className="addr">
              <table className="date">
                <tr>
                  <th>Order Date:</th>
                  <td className="invoicedata">{this.state.order.order_date}</td>
                </tr>
                <tr>
                  <th>Order Id:</th>
                  <td>{this.state.order._id}</td>
                </tr>
                <tr>
                  <th>Course Name:</th>
                  <td className="invoicedata">{this.courseName}</td>
                </tr>
              </table>
            </div>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Base Price</th>
                  <th scope="col">Tax Rate</th>
                  <th scope="col">Tax Type</th>
                  <th scope="col">Tax Value</th>
                  <th scope="col">Discount Value</th>
                  <th scope="col">Final Price</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td scope="col">{1}</td>
                  <td scope="col">
                    <>{currencyVal} </>
                    {this.state.order.base_price}
                  </td>
                  <td scope="col">{this.taxRate}</td>
                  <td scope="col">{this.taxType}</td>
                  <td scope="col">{this.taxValue}</td>
                  <td scope="col">-{this.state.order.discount}</td>
                  <td scope="col">
                    <>{currencyVal} </>
                    {this.state.order.final_price}
                  </td>
                </tr>
                <tr>
                  <td colSpan="6">Total:</td>
                  <td scope="col">
                    <>{currencyVal} </>
                    {this.state.order.final_price}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="btn">
          <Row>
            <Col sm={6} md={12}>
              <div className="col">
                <div className="form-group">
                  <label htmlFor="copounCode" className="sr-only">
                    CopounCode
                  </label>
                  <input
                    type="text"
                    id="copounCode"
                    className="form-control"
                    placeholder="Enter copoun code"
                    onChange={this.handlecopoun.bind(this)}
                    required
                    autoFocus
                  />
                  <div className="errormsgodr">{this.state.error ? this.state.error : " "}</div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <button
              type="button"
              className="btn btn-dark effect btn-sm"
              id="button1"
              onClick={this.submitOrder.bind(this)}
            >
              {" "}
              <i className="fas fa-chart-bar"></i> Apply Coupon
            </button>
            <button
              type="button"
              className="btn btn-dark effect btn-sm"
              id="button2"
              onClick={this.saveOrder.bind(this)}
            >
              {" "}
              <i className="fas fa-chart-bar"></i> CheckOut
            </button>
          </Row>
          
        </div>
      </>
    );
  }
}
