import React, { useEffect } from 'react';
import TopCategory from "../topCategory/top.category";
import Banner from '../Banner/banner';
import PopularCourse from '../popularCourse/popular.course';
import NewsSubscription from '../newsAndSubscriptions/newsAndSubscriptions';
import BannerService from "../../services/bannerService";
import Testimonials from "../../pages/testimonial/testimonial";

export default class Home extends React.Component {

  constructor(props) {
    super(props);
    this.search = { start: 0, perPage: 12, searchTxt: "", searchField: "", refresh: false };
    this.state = {};

    this.bannerServ = new BannerService();
  }
  componentDidMount() {

    this.getBannerList();
    window.scrollTo(0, 0);
  }
  getBannerList() {
    this.bannerServ.listBanner(
      this.search.start, this.search.perPage,
      this.search.searchTxt, this.search.searchField
    )
      .then(
        (response) => {
          this.bannerList = [];
          for (let i = 0; i < response.data.length; i++) {
            this.bannerList.push(response.data[i]);
          }

          this.setState({ bannerList: response.data });
        },
        (error) => {
          this.setState({ bannerList: [] });
        }
      );
  }
  render() {
    let data = "";
    let pagename = "Home";
    if (this.bannerList) {
      for (let i = 0; i < this.bannerList.length; i++) {
        if (this.bannerList[i].title === "Advance Courses For All") {
          data = this.bannerList[i].title
          pagename = this.bannerList[i].pagename
        }
      }
    }
    return (
      <div>
        <Banner pagename={pagename} pagelink={"/"} title={data} showSearch={true} />
        <TopCategory />
        <PopularCourse />
        <Testimonials />
        <NewsSubscription />
      </div>
    );
  }
}
