import util from '../utils/util';

export default  class UserService{

    likesCount(course_id) {
        return util.sendApiRequest("/likes","POST",true,{course:course_id}).then(
                (response)=>{
                    return response;
                },
                (error)=>{
                    throw new Error(error);
                }
            );
    }
    listLike() {
         return util.sendApiRequest("/likes/list", "GET",true).then(
             (response)=>{
                 return response;
             },
             (error)=>{
                 throw new Error(error);
             },
         );
     }
}
