import React, { useState, useEffect } from "react";
import blogService from "../../services/blogService";
import { Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function EventMetting(props) {
  const blogServ = new blogService();
  const { meetingId, name } = props.match.params;
  const [show, setshow] = useState(false);
  let timeId;
  useEffect(() => {
    handleClickevent();
    timeId = setInterval(() => {
      handleClickevent();
    }, 10000);
    return () => {
      clearInterval(timeId);
    };
  }, []);

  const handleClickevent = async () => {
    try {
      setshow(true);
      let result = await blogServ.joinMeeting({ meetingId, name });
      window.open(result.data.data);
      clearInterval(timeId);
      setshow(false);
    } catch (error) {
      toast.error("Event not yet started");
      setshow(false);
    }
  };
  return (
    <div style={{ textAlign: "center", height: "18rem", marginTop: "14rem" }}>
      <Button size="sm" onClick={handleClickevent}>
        Join event {show && <i className="fas fa-spinner fa-spin fa-1x"></i>}
      </Button>
      <ToastContainer />
    </div>
  );
}

export default EventMetting;
