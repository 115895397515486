import React from "react";
import Timer from "./showTimer";

class QuestionTimer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seconds: "00",
      minutes: "00",
      originalMin: 0,
    };

    this.intervalHandle = 0;
    // method that triggers the countdown functionality
    this.startCountDown = this.startCountDown.bind(this);
    this.tick = this.tick.bind(this);
  }
  componentWillReceiveProps(prevProps) {
    if (prevProps.minutes == null) {
      this.setState({ minutes: "00", seconds: "00" });
    }
    if (prevProps.minutes)
      if (prevProps.minutes !== this.state.originalMin) {
        this.setState({ originalMin: prevProps.minutes }, () => {
          this.setState({ minutes: prevProps.minutes }, () => {
            this.startCountDown();
          });
        });
        clearInterval(this.intervalHandle);
      }
  }

  componentWillUnmount() {
    clearInterval(this.intervalHandle);
  }
  tick() {
    var min = Math.floor(this.secondsRemaining / 60);
    var sec = this.secondsRemaining - min * 60;
    this.setState({
      minutes: min,
      seconds: sec,
    });
    if (sec < 10) {
      this.setState({
        seconds: "0" + this.state.seconds,
      });
    }
    if (min < 10) {
      this.setState({
        minutes: "0" + min,
      });
    }
    if (min == 0 && sec == 0) {
      this.props.handleTimer(true);

      clearInterval(this.intervalHandle);
    }
    this.secondsRemaining--;
  }
  startCountDown() {
    this.intervalHandle = setInterval(this.tick, 1000);
    let time = this.props.minutes;
    this.secondsRemaining = time * 60;
  }
  render() {
    this.props.handleClearInterval(this.intervalHandle);
    return <div>{this.props.minutes ? <Timer time={this.state.minutes} seconds={this.state.seconds} /> : null}</div>;
  }
}

export default QuestionTimer;
