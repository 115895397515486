import React, { useEffect, useState } from "react";
import QuizeeService from "../../services/quizzeService";
import { Table, Row, Col, Container } from "react-bootstrap";
import { Redirect } from "react-router-dom";

function CourseResult(props) {
  let quizServ = new QuizeeService();
  const [quizList, setquizList] = useState([]);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    quizServ.courseResult(props.match.params.id).then(
      (response) => {
        setquizList(response.data);
      },
      (error) => {
        alert("Oops, something went wrong not able to fetch Course  details.");
      }
    );
  }, []);
  const handleCouseCertificateDownload = async () => {
    let certificateData = {};
    certificateData.crsId = props.match.params.id;
    certificateData.studId = window.user.data._id;
    let token = window.user ? window.user.token : "no-token";
    const url = window.apiurl + "/certificate";
    const data = certificateData;
    setloading(true);
    try {
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      if (response) {
        setloading(false);
      }
      const json = await response.blob();
      let blob = new Blob([json], { type: "application/pdf" });
      let downloadUrl = URL.createObjectURL(blob);
      //Build a URL from the file
      window.open(downloadUrl);
    } catch (error) {}
  };
  if (props?.location?.state?.is_course_completed == undefined) {
    return <Redirect to="/" />;
  }

  if (!quizList.length) {
    window.scroll(0, 0);
    return <div style={{ textAlign: "center", height: "18rem", marginTop: "14rem" }}>No Record Found</div>;
  }

  return (
    <Container>
      <div style={{ marginTop: "50px" }}></div>
      <div className="row">
        <div className="col-md-6"></div>
        <div className="col-md-6">
          {props?.location?.state?.is_course_completed ? (
            <div style={{ textAlign: "right" }}>
              <button
                type="button"
                className="btn btn-dark effect btn-sm"
                id="button1"
                onClick={() => {
                  handleCouseCertificateDownload();
                }}
              >
                <i className="fas fa-download"></i> Download Certificate{" "}
                {loading ? <i class="fas fa-spinner fa-spin"></i> : null}{" "}
              </button>
            </div>
          ) : null}
        </div>
      </div>
      <div style={{ marginTop: "10px" }}></div>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>#</th>

            <th>Quiz Name</th>
            <th>Course Name</th>
            <th>Marks Obtained</th>
            <th>Result</th>
          </tr>
        </thead>

        <tbody>
          {quizList.map((el, i) => {
            return (
              <tr key={el._id}>
                <td>{i + 1}</td>
                <td>{el["quizzeName"]}</td>
                <td>{el["courseName"]}</td>
                <td>{el.needAction ? "-" : el["marksObtained"]}</td>
                <td>
                  {el["result"] == "Passed" ? (
                    <div style={{ float: "unset" }} className="success">
                      {el["result"]}
                    </div>
                  ) : el["result"] == "Failed" ? (
                    <div style={{ float: "unset" }} className="failed">
                      {el["result"]}
                    </div>
                  ) : (
                    <div style={{ float: "unset" }} className="wait">
                      {el["result"]}
                    </div>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div style={{ marginTop: "100px" }}></div>
    </Container>
  );
}

export default CourseResult;
