import React from 'react';
import { Link } from "react-router-dom";
import BannerService from "../../services/bannerService";
import CourseService from "../../services/courseService";
import SearchBar from '../../components/searchbar';
export default  class HomeBanner extends React.Component{

    constructor(props) {
        super(props);
        this.search = {start:0,perPage:12,searchTxt:"",searchField:""};
        this.state = {courseList:[]};

        this.courseServ = new CourseService();
        this.bannerServ = new BannerService();

    }
    componentDidMount(){
        this.getBannerList();
    }
    getBannerList(){
        this.bannerServ.listBanner(
                this.search.start,this.search.perPage,
                this.search.searchTxt,this.search.searchField
            )
            .then(
                (response)=>{
                    this.bannerList = []
                    for(let i= 0; i< response.data.length; i++){
                        this.bannerList.push(response.data[i]);
                    }
                    this.setState({bannerList: response.data});
                },
                (error)=>{
                    this.setState({bannerList: []});
                }
            );
    }

    showBreadcrumbs(bgclass,bgImage){
        return (<div className={"breadcrumb-area shadow dark text-center bg-fixed text-light " + bgclass} style={bgImage}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h1>{this.props.title}</h1>
                        <ul className="breadcrumb">
                            <li><Link to={this.props.pagelink}><i className="fas fa-home"></i>Home</Link></li>
                            <li className="active">{this.props.pagename}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>);
    }

    showSearch(bgclass,bgImage){
        return (<div className={"banner-area auto-height shadow dark text-light content-top-heading bg-fixed text-normal text-center "+bgclass}  style = {bgImage}> 
            <div className="item">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-md-offset-2">
                            <div className="content">
                                <h1>{this.props.title}</h1>
                                <SearchBar categories={['name']} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>     
        </div>);
    }
    
    render() {
        let bgclass = "";
        var bgImage = {};
        if(this.bannerList){
            if(this.props.pagename){
                for(let i = 0;i<this.bannerList.length; i++){
                    if(this.props.pagename === this.bannerList[i].pagename){
                        bgImage = { backgroundImage: "url('" + this.bannerList[i].image + "')" };
                        break;
                    }
                }
            }
        }
        if(this.props.showSearch == true){ 
            return this.showSearch(bgclass,bgImage);
        }
        else{
            return this.showBreadcrumbs(bgclass,bgImage);
        }
    }
}