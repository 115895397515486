import util from "../utils/util";

export default class messageService {
  getmessages(courseId, teacherId, userId) {
    return util.sendApiRequest("/message/" + courseId + "/" + teacherId + "/" + userId, "GET", true).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }

  sendMessage(data) {
    return util.sendApiRequest("/message", "POST", true, data).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }
}
