import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, withRouter } from "react-router-dom";

function BackButton(props) {
  return (
    <Link
      style={{ textDecoration: "underline", cursor: "pointer", display: "inline-flex", color: props.textColor }}
      to={{ pathname: "/user/profile", state: { prevPath: "/user/course" } }}
    >
      &lt;Back
    </Link>
  );
}

export default withRouter(BackButton);
