import React from 'react';
import Banner from '../Banner/banner';
import News from '../../pages/blogs/news';
import Events from '../../pages/blogs/event';
import Announcement from '../../pages/blogs/announcement';

export default class Blogs extends React.Component {

    constructor(props) {
        super(props);
    }
    render() {
        return (
          <>
            <Banner
              pagename={"News Announcements And Events"}
              pagelink={"/blogs"}
              title={"News Announcements And Events"}
              showSearch={false}
            />
            <div className="blog-area full-blog standard full-blog">
              <div className="container">
                <div className="row">
                  <div className="blog-items">
                    <div className="blog-content col-md-10 col-md-offset-1">
                      <div className="single-item">
                        <div className="students-profiel adviros-details-area default-padding">
                          <div className="col-md-15 info main-content">
                            <div className="tab-info">
                              <ul className="nav nav-pills">
                                <li className="active">
                                  <a data-toggle="tab" href="#tab1" aria-expanded="true">
                                    News
                                  </a>
                                </li>
                                <li>
                                  <a data-toggle="tab" href="#tab2" aria-expanded="false">
                                    Events
                                  </a>
                                </li>
                                <li>
                                  <a data-toggle="tab" href="#tab3" aria-expanded="false">
                                    Announcements
                                  </a>
                                </li>
                              </ul>
                              <div className="tab-content tab-content-info">
                                <div id="tab1" className="tab-pane fade active in">
                                  <News />
                                </div>
                                <div id="tab2" className="tab-pane fade">
                                  <div className="info title">
                                    <Events />
                                  </div>
                                </div>
                                <div id="tab3" className="tab-pane fade">
                                  <div className="info title">
                                    <Announcement />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
    }
}



