import util from '../utils/util';

export default  class CourseCategoryService{
    getCategory(id) {
        return util.sendApiRequest("/course/category/"+id,"GET",true).then(
            (response)=>{
                return response;
            },
            (error)=>{
                throw new Error(error);
            },
        );
    }
    editCategory(category) {
        return util.sendApiRequest("/course/category","PUT",true,category).then(
            (response)=>{
                return response;
            },
            (error)=>{
                throw new Error(error);
            },
        );
    }
    listCategory(start,length,searchTxt,searchField) {
       let url = "/course/category/list/"+start+"/"+length;
       if(searchTxt && searchField){
        url = "/course/category/list/"+start+"/"+ length+"/"+searchTxt+"/"+searchField.toLowerCase();
       }
       
        return util.sendApiRequest(url, "GET",true).then(
            (response)=>{
                return response;
            },
            (error)=>{
                throw new Error(error);
            },
        );
    }
    addCategory(category) {
        return util.sendApiRequest("/course/category","POST",true,category).then(
            (response)=>{
                return response;
            },
            (error)=>{
                throw new Error(error);
            },
        );
    }
    deleteCategory(id) {
        return util.sendApiRequest("/course/category/"+id,"DELETE",true).then(
            (response)=>{
                return response;
            },
            (error)=>{
                throw new Error(error);
            },
        );
    }
    courseCategoryFrontend() {
        let url = "/course/coursecategoryfrontend"
         return util.sendApiRequest(url, "GET",true).then(
             (response)=>{
                 return response;
             },
             (error)=>{
                 throw new Error(error);
             },
         );
     }
    
}