import util from '../utils/util';

export default  class SettingService{
    listSetting(start,length,searchTxt,searchField) {
        let url = "/setting/list/"+start+"/"+length;
        if(searchTxt && searchField){
         url = "/setting/list/"+start+"/"+ length+"/"+searchTxt+"/"+searchField.toLowerCase();
        }
        
         return util.sendApiRequest(url, "GET",true).then(
             (response)=>{
                 return response;
             },
             (error)=>{
                 throw new Error(error);
             },
         );
     }
}