import util from '../utils/util';

export default  class NewsSubscriptionService{

    savenewssubscription(name,email,recaptcha) {
        return util.sendApiRequest("/news","POST",true,{name:name,email:email,recaptcha:recaptcha}).then(
                (response)=>{
                    return response;
                },
                (error)=>{
                    throw new Error(error);
                }
            );
    }
}