import util from "../utils/util";

export default class BbbService {
  joinMetting(data) {
    return util.sendApiRequest("/bbb/join_meeting", "POST", true, data).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }
  getRecordingList(data) {
    return util.sendApiRequest("/bbb/get_recordings", "POST", true, data).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }
}
