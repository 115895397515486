import React from "react";
import Timer from "./showTimer";

class T extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seconds: "00",
      minutes: 0,
      originalMin: 0,
    };
    // this.secondsRemaining;
    this.intervalHandle = 0;
    // method that triggers the countdown functionality
    this.startCountDown = this.startCountDown.bind(this);
    this.tick = this.tick.bind(this);
  }
  componentDidMount() {
    this.setState({ originalMin: this.props.minutes }, () => {
      this.setState({ minutes: this.props.minutes }, () => {
        this.startCountDown();
      });
    });
  }

  componentWillReceiveProps(prevProps) {
    if (prevProps.minutes !== this.state.originalMin) {
      this.setState({ originalMin: prevProps.minutes }, () => {
        this.setState({ minutes: prevProps.minutes }, () => {
          this.startCountDown();
        });
      });
      clearInterval(this.intervalHandle);
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalHandle);
  }
  tick() {
    var min = Math.floor(this.secondsRemaining / 60);
    var sec = this.secondsRemaining - min * 60;
    this.setState({
      minutes: min,
      seconds: sec,
    });
    if (sec < 10) {
      this.setState({
        seconds: "0" + this.state.seconds,
      });
    }
    if (min < 10) {
      this.setState({
        minutes: "0" + min,
      });
    }
    if ((min == 0) & (sec == 0)) {
      this.props.handleTimer(true, this.intervalHandle);
      clearInterval(this.intervalHandle);
    }
    this.secondsRemaining--;
  }
  startCountDown() {
    this.intervalHandle = setInterval(this.tick, 1000);
    let time = this.props.minutes;
    this.secondsRemaining = time * 60;
  }
  render() {
    this.props.handletimerInterval(this.intervalHandle); // interval for quizz timer
    return <div>{this.props.minutes ? <Timer time={this.state.minutes} seconds={this.state.seconds} /> : null} </div>;
  }
}

export default T;
