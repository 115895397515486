import React from "react";
import Iframe from "react-iframe";
import CourseService from "../../services/courseService";
import Container from "react-bootstrap/Container";
import InternalCourse from "./InternalOnlineCourse";
import LiveSessionDisplayCourse from "./LiveSessionDisplayCourse";
import Setting from "../../services/settingService";
import { Link } from "react-router-dom";
import TutorQuestion from "./TutorEvalquestion.json";
import moment from "moment";
import { Row, Col, ListGroup } from "react-bootstrap";
import { CheckBoxweekDays } from "./CheckBoxweekDays";
import JoinMeetingModal from "./JoinMettingModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LiveRecordingList from "./LiveRecordingList";
import Summary from "./Summary";
import LiveSessionCourse from "./LiveSessionCourse";
import BackButton from "../../components/BackButton";
export default class UserCourse extends React.Component {
  constructor(props) {
    super(props);
    this.course_id = props.match.params.id;
    this.state = { course: null, onlineSecret: null, onlineJoinUrl: null };
    this.courseServ = new CourseService();
    this.settingServ = new Setting();
    this.weekDays = [
      { value: "sunday", name: "Sun" },
      { value: "monday", name: "Mon" },
      { value: "tuesday", name: "Tue" },
      { value: "wednesday", name: "Wed" },
      { value: "thrusday", name: "Thu" },
      { value: "friday", name: "Fri" },
      { value: "saturday", name: "Sat" },
    ];
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.course_id) {
      this.courseServ.getCourse(this.course_id, this.props.match.params.user).then(
        (response) => {
          this.setState({
            course: response.data,
          });
        },
        (error) => {
          alert("Oops, something went wrong not able to fetch course.");
        }
      );
    }
    this.getSettingList();
  }

  getSettingList() {
    this.settingServ.listSetting().then(
      (response) => {
        response.data.forEach((element) => {
          if (element.name === "online_session_URL") {
            this.setState({ onlineJoinUrl: element.value });
          }
        });
      },
      (error) => {
        alert("Oops, something went wrong not able to fetch Setting list.");
      }
    );
  }
  render() {
    const { course } = this.state;

    const liveCourseData = () => {
      let data;
      switch (course.time) {
        case "daily":
          data = (
            <>
              <h4 style={{ marginTop: "10px" }}>Live session start daily at : </h4>
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Start Date</th>
                    <th scope="col">Start Time</th>
                    <th scope="col">End Date</th>
                    <th scope="col">End Time</th>
                  </tr>
                </thead>
                <tbody>
                  <td scope="col">1</td>
                  <td scope="col">{moment(course.start_date).format("ll")}</td>
                  <td scope="col"> {moment(course.start_time).format("hh:mm a")}</td>
                  <td scope="col">{moment(course.end_date).format("ll")}</td>
                  <td scope="col">{moment(course.end_time).format("hh:mm a")}</td>
                </tbody>
              </table>
            </>
          );
          break;
        case "weekly":
          data = (
            <>
              <h4 style={{ marginTop: "10px" }}>
                <div className="row">
                  <div style={{ padding: "18px" }} className="col-md-4">
                    Live session start weekely on :{" "}
                  </div>
                  {this.weekDays.map((el, i) => (
                    <CheckBoxweekDays weekVal={course.weekDays} key={el.name} name="weekDays" value={el.name} />
                  ))}
                </div>
              </h4>

              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Start Date</th>
                    <th scope="col">Start Time</th>
                    <th scope="col">End Date</th>
                    <th scope="col">End Time</th>
                  </tr>
                </thead>
                <tbody>
                  <td scope="col">1</td>
                  <td scope="col">{moment(course.start_date).format("ll")}</td>
                  <td scope="col"> {moment(course.start_time).format("hh:mm a")}</td>
                  <td scope="col">{moment(course.end_date).format("ll")}</td>
                  <td scope="col">{moment(course.end_time).format("hh:mm a")}</td>
                </tbody>
              </table>
            </>
          );
          break;
        case "custom":
          data = (
            <>
              <h4 style={{ marginTop: "10px", marginBottom: "10px" }}>Live session start on these following dates:</h4>

              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Start Date</th>
                    <th scope="col">Start Time</th>
                    <th scope="col">End Date</th>
                    <th scope="col">End Time</th>
                  </tr>
                </thead>
                <tbody>
                  <td scope="col">1</td>
                  <td scope="col">{moment(course.start_date).format("ll")}</td>
                  <td scope="col"> {moment(course.start_time).format("hh:mm a")}</td>
                  <td scope="col">{moment(course.end_date).format("ll")}</td>
                  <td scope="col">{moment(course.end_time).format("hh:mm a")}</td>
                </tbody>
              </table>
              <ListGroup style={{ fontSize: "14px", color: "#002147" }}>
                <Row>
                  {course.selectedDays.map((el, i) => {
                    return (
                      <Col md={3} key={i}>
                        <ListGroup.Item>{moment(el).format("ll")}</ListGroup.Item>
                      </Col>
                    );
                  })}
                </Row>
              </ListGroup>
            </>
          );
          break;

        default:
          data = <div>loading</div>;
      }
      return data;
    };
    let coursedata;
    if (this.state.course) {
      if (this.state.course.end_date===null && this.state.course.section.length===0) {
        coursedata = (
          <Container style={{ minHeight: "600px", marginTop: "10px" }}>
            <BackButton textColor="black" />
                <div className="feedback-internal">
                  <Link
                      className="crs-btn"
                      to={
                        `/testimonial/${this.course_id}`
                      }
                    >
                        Add testimonial
                    </Link>
                </div>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Download</th>
                </tr>
              </thead>
              <tbody>
                {this.state.course.offline_course_content.map((el, i) => {
                  return (
                    <tr>
                      <th scope="row">{i + 1}</th>
                      <td>{el.split("$").slice(1).join()}</td>
                      <td>
                        <a target="_blank" href={el}>
                          Download
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Container>
        );
      } else if (this.state.course.course_type === "online") {
        coursedata = (
          <>
            <Iframe
              url={
                this.state.course.view_course_content +
                `?endpoint=${window.apiurl}/xapi&auth=Basic ${window.user.token}&actor={"name":["${window.user.data._id}"],"id":["${window.user.data._id}"],"mbox":["mailto:${window.user.data.email}"]}&activity_id=http://${this.course_id}`
              }
              width="100%"
              height="550px"
              id="myId"
              className="myClassname"
              display="initial"
              position="relative"
              allowFullScreeen={true}
              target="_blank"
              controls
            />
            <div className="feedback-tincan">
              <Link
                to={{
                  pathname: `/feedback/${this.course_id}`,
                  state: {
                    questions: TutorQuestion,
                    title: "Tutor Evaluation Form",
                    type: "tutor_evaluation",
                    teacherId: this.state.course.created_by._id,
                  },
                }}
              >
                Feedback
              </Link>
            </div>
          </>
        );
      } else if (this.state.course.start_date!==null && this.state.course.end_date!==null) {
        coursedata = (
          <>
            <Container style={{ minHeight: "600px", marginTop: "10px" }}>
              <div className="row crs-name">
                <div className="col-sm-6 col-xs-6 name">
                  <BackButton textColor="white" />
                  <div className="coursetitle">{course.name}</div>
                </div>
                <div className="col-sm-6 col-xs-6">
                <div className="feedback-internal">
                  <Link
                     className="crs-btn"
                    to={
                      `/testimonial/${this.course_id}`
                    }
                  >
                    Add testimonial
                  </Link>
                </div>
              </div>
              </div>
              {course.live_sesson_file_content.length ? (
                <>
                  <h4 style={{ marginTop: "10px" }}>Course Files: </h4>
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Download</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.course.live_sesson_file_content.map((el, i) => {
                        return (
                          <tr>
                            <th scope="row">{i + 1}</th>
                            <td>{el.split("$").slice(1).join()}</td>
                            <td>
                              <a target="_blank" href={el}>
                                Download
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>{" "}
                </>
              ) : null}
               <LiveSessionDisplayCourse {...this.props} />;
              <div className="" style={{ marginBottom: "13px", marginTop: "13px", textAlign: "center" }}>
                <JoinMeetingModal courseId={this.state.course._id} studentName={window.user?.data?.first_name} />
                             
              </div>

              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <LiveSessionCourse
                      displayAccordian = {true}
                      startDate={course.start_date}
                      endDate={course.end_date}
                      startTime={course.start_time}
                      endTime={course.end_time}
                      type={course.time}
                      weekVal={course.weekDays}
                      selectedDays={course.selectedDays}
                    />
                  </div>
                  <div className="col-md-6">
                    <Summary summary={course.summary} />
                  </div>
                </div>
              </div>
              <LiveRecordingList courseId={this.state.course._id} crsName={this.state.course.name} />
             
            </Container>
          </>
        );
      } else if (this.state.course.end_date===null && this.state.course.section.length >0) {
        return <InternalCourse {...this.props} />;
      } else {
        coursedata = (
          <div className="course-body">
            <strong>Your Course Will Be Avaliable Soon.</strong>
          </div>
        );
      }
    }
    return (
      (
        <>
          {coursedata} <ToastContainer />
        </>
      ) || <></>
    );
  }
}
