import React from "react";
import Banner from "../Banner/banner";
import ContactService from "../../services/contactusService";
import { Redirect } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Setting from "../../services/settingService";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Formik, ErrorMessage } from "formik";
import BannerService from "../../services/bannerService";
import * as Yup from "yup";
export default class contactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = { contact: "", redirect: false };
    this.state.contact = {
      name: "",
      email: "",
      address: "",
      description: "",
      phoneNumber : ""
    };
    this.search = { start: 0, perPage: 12, searchTxt: "", searchField: "" };

    this.bannerServ = new BannerService();
    this.contactServ = new ContactService();
    this.settingServ = new Setting();
    const phoneNumberRegex = RegExp(
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
    )
    this.schema = Yup.object({
      name: Yup.string().required('Name is a required field'),
      email: Yup.string().required('Email is a required field').email("Invalid email"),
      address: Yup.string().required('Address is a required field'),
      description: Yup.string().required('Description is arequired field'),
      phoneNumber: Yup.string()
        .matches(phoneNumberRegex, 'Invalid phone number')
        .required('Phone number is a required field'),
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getBannerList();
    this.settingfn();
  }

  settingfn() {
    this.settingServ.listSetting(0, 1000000).then(
      (response) => {
        this.setState({ settingList: response.data });
      },
      (error) => {
        this.setState({ settingList: [] });
      }
    );
  }

  getBannerList() {
    this.bannerServ
      .listBanner(this.search.start, this.search.perPage, this.search.searchTxt, this.search.searchField)
      .then(
        (response) => {
          this.bannerList = [];
          for (let i = 0; i < response.data.length; i++) {
            this.bannerList.push(response.data[i]);
          }

          this.setState({ bannerList: response.data });
        },
        (error) => {
          this.setState({ bannerList: [] });
        }
      );
  }

  submitForm(values, actions) {
    actions.setSubmitting(false);
    this.setState({
      contact: values,
    });
    this.contactServ.addContact(this.state.contact).then(
      (response) => {
        this.setState({ redirect: true });
      },
      (error) => {
        this.setState({ redirect: false });
      }
    );
  }

  render() {
    let email = "",
      officeaddr = "",
      contact = "";

    if (this.state.redirect === true) {
      window.location.reload(false);
      return <Redirect to="/contact"></Redirect>;
    }

    let data = "";
    let pagename = "";
    if (this.bannerList) {
      for (let i = 0; i < this.bannerList.length; i++) {
        if (this.bannerList[i].title === "Contact Us") {
          data = this.bannerList[i].title;
          pagename = this.bannerList[i].pagename;
        }
      }
    }
    if (this.state.settingList) {
      for (let i = 0; i < this.state.settingList.length; i++) {
        if (this.state.settingList[i].name === "Email") {
          email = this.state.settingList[i].value;
        }
        if (this.state.settingList[i].name === "office address") {
          officeaddr = this.state.settingList[i].value;
        }
        if (this.state.settingList[i].name === "Contact") {
          contact = this.state.settingList[i].value;
        }
      }
    }
    if (this.state.redirect === true) {
      return <Redirect to="/contact" />;
    }
    return (
      <>
        <Banner pagename={pagename} pagelink={"/contactus"} title={data} showSearch={false} />
        <div className="contact-info-area default-padding">
          <div className="container">
            <div className="row">
              <div className="contact-info">
                <div className="col-md-4 col-sm-4">
                  <div className="item">
                    <div className="icon">
                      <i className="fas fa-mobile-alt"></i>
                    </div>
                    <div className="info">
                      <h4>Call Us</h4>
                      <span>{contact}</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-4">
                  <div className="item">
                    <div className="icon">
                      <i className="fas fa-map-marker-alt"></i>
                    </div>
                    <div className="info">
                      <h4>Address</h4>
                      <span>{officeaddr}</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-4">
                  <div className="item">
                    <div className="icon">
                      <i className="fas fa-envelope"></i>
                    </div>
                    <div className="info">
                      <h4>Email Us</h4>
                      <span>{email}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="seperator col-md-12">
                <span className="border"></span>
              </div>
              <div className="maps-form">
                <div className="col-md-6 maps">
                  <h3>Our Location</h3>
                  <div className="google-maps">
                  <iframe src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ1ajOGBpMzDERkgJALm_2hv8&key=AIzaSyBPQG_ejVRygDJA-_oPM0KpHpcAQOnJe-0" controls></iframe>
                    {/* <iframe src="https://www.google.com/maps/embed/v1/search?q=31F-2,+2nd+Floor,+Kelana+Mall,+Jalan+SS6%2F12,+47301+Petaling+Jaya,+Selangor,+Malaysia&key=AIzaSyBPQG_ejVRygDJA-_oPM0KpHpcAQOnJe-0" controls></iframe> */}
                  </div>
                </div>
                <div className="col-md-6 form">
                  <div className="heading">
                    <h3>Contact Us</h3>
                    <p>
                    Please send us a message and we will get back to you soon.
                    </p>
                  </div>
                  <Formik
                    validationSchema={this.schema}
                    initialValues={this.state.contact}
                    enableReinitialize={true}
                    onSubmit={this.submitForm.bind(this)}
                    render={({
                      values,
                      errors,
                      status,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <div className="address addresslist">
                        <Container>
                          <Form onSubmit={handleSubmit}>
                            <Row>
                              <Col sm={9} md={6}>
                                <Form.Group>
                                  <Form.Control
                                    type="text"
                                    placeholder="Email*"
                                    value={values.email}
                                    name="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isValid={touched.email && !errors.email}
                                  />
                                  <ErrorMessage name="email">
                                    {(msg) => <div className="err_below">{msg}</div>}
                                  </ErrorMessage>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={9} md={6}>
                                <Form.Group>
                                  <Form.Control
                                    type="text"
                                    placeholder="Name*"
                                    value={values.name}
                                    name="name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isValid={touched.name && !errors.name}
                                  />
                                  <ErrorMessage name="name">
                                    {(msg) => <div className="err_below">{msg}</div>}
                                  </ErrorMessage>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={9} md={6}>
                                <Form.Group>
                                  <Form.Control
                                    type="text"
                                    placeholder="Address*"
                                    value={values.address}
                                    name="address"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isValid={touched.address && !errors.address}
                                  />
                                  <ErrorMessage name="address">
                                    {(msg) => <div className="err_below">{msg}</div>}
                                  </ErrorMessage>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={9} md={6}>
                                <Form.Group>
                                  <Form.Control
                                    type="text"
                                    placeholder="Phone number*"
                                    value={values.phoneNumber}
                                    name="phoneNumber"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isValid={touched.phoneNumber && !errors.phoneNumber}
                                  />
                                  <ErrorMessage name="phoneNumber">
                                    {(msg) => <div className="err_below">{msg}</div>}
                                  </ErrorMessage>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={9} md={6}>
                                <Form.Group>
                                  <Form.Control
                                    type="text"
                                    placeholder="Please enter your message here*"
                                    value={values.description}
                                    name="description"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isValid={touched.description && !errors.description}
                                  />
                                  <ErrorMessage name="description">
                                    {(msg) => <div className="err_below">{msg}</div>}
                                  </ErrorMessage>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <div className="col-md-12">
                                <div className="row">
                                  <button type="submit" name="submit" id="submit">
                                    Send Message <i className="fa fa-paper-plane"></i>
                                  </button>
                                </div>
                              </div>
                              <div className="col-md-12 alert-notification">
                                <div id="message" className="alert-msg"></div>
                              </div>
                            </Row>
                          </Form>
                        </Container>
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
