import React, { Component } from "react";
import { Formik, ErrorMessage } from "formik";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import UserService from "../../services/userService";
import Iframe from "react-iframe";
import countryList from "../../components/countryList.json";

const axios = require("axios");
export default class UserForm extends Component {
  constructor(props) {
    super(props);
    this.passwordref = null;
    this.user_id = window.user.data._id;
    this.image = "";
    this.state = {
      totalCount: 0,
      file: null,
      redirect: false,
      loading: false,
      scrollPosition: 400,
      sucessMessage: "",
      errorMessage: "",
      profileImg: "",
      orderList: [],
      userCourse: [],
      likescount: [],
      notificationList: [],
      settingList: [],
      student: {
        title: "Mr",
        first_name: "",
        last_name: "",
        email: "",
        address_1: "",
        address_2: "",
        address_3: "",
        town: "",
        state: "",
        country: "",
        city: "",
        zipcode: "",
        phone: "",
        recieve_newsletter: false,
        receive_personalised: false,
        is_active: false,
        courses: [],
        roles: [],
        password: "",
        newPassword: "",
        verifyPassword: "",
        dob: "",
        gender: "",
        race: "",
        ethnicity: "",
        marital_status: "",
        income: "",
        education: "",
        employment: "",
        industry: "",
      },
    };
    const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{5}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)?$/;
    this.schema = Yup.object({
      title: Yup.string(),
      first_name: Yup.string().required("first name is a required field"),
      last_name: Yup.string().required("last name is a required field"),
      email: Yup.string().required("email is a required field").email(),
      address_1: Yup.string().required("address 1 is a required field"),
      address_2: Yup.string(),
      address_3: Yup.string(),
      country: Yup.string().required("country is a required field"),
      state: Yup.string().required("state is a required field"),
      city: Yup.string().required("city is a required field"),
      passsword: Yup.string(),
      zipcode: Yup.string().required("zipcode is a required field"),
      phone: Yup.string().required("phone number is a required field").matches(phoneRegExp, "invalid phone number"),
      town: Yup.string(),
      dob: Yup.date(),
      gender: Yup.string(),
      race: Yup.string(),
      ethnicity: Yup.string(),
      marital_status: Yup.string(),
      income: Yup.string(),
      education: Yup.string(),
      employment: Yup.string(),
      industry: Yup.string(),
    });

    this.userServ = new UserService();
  }
  submitForm(values, actions) {
    const formData = new FormData();
    if (values.user_image) {
      formData.append("image", values.image);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      axios
        .post(window.apiurl + "/user/upload/file", formData, config)
        .then((response) => {
          delete values.user_image; //deleting user blob url
          values.image = response.data.originalname;
          this.submitStudentForm(values, actions);
        })
        .catch((error) => {});
    } else {
      this.submitStudentForm(values, actions);
    }
  }
  submitStudentForm(values, actions) {
    if (this.user_id) {
      this.userServ.editUser(values).then(
        (response) => {
          actions.setSubmitting(false);
          toast.success("profile updated");
          window.scroll(30, 60);
          this.props.handleUserName(response);
        },
        (error) => {
          actions.setSubmitting(false);
          toast.error(error.message);
        }
      );
    }
  }

  render() {
    let countryOption = [];
    for (let i = 0; i < countryList.length; i++) {
      countryOption.push(
        <option key={countryList[i].Code + " " + i} value={countryList[i].Name}>
          {countryList[i].Name}
        </option>
      );
    }
    return (
      <div>
        <Formik
          validationSchema={this.schema}
          initialValues={window.user.data || this.props.student}
          enableReinitialize={true}
          onSubmit={this.submitForm.bind(this)}
          render={({
            values,
            errors,
            status,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <div className="address addresslist">
              <Container>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col sm={9} md={6}>
                      <Form.Label>Title*</Form.Label>
                      <Form.Control
                        as="select"
                        name="title"
                        value={values.title}
                        onChange={handleChange}
                        isValid={touched.title && !errors.title}
                      >
                        <option value="Dr">Dr</option>
                        <option value="Mr">Mr</option>
                        <option value="Mrs">Mrs</option>
                        <option value="Miss">Miss</option>
                        <option value="Ms">Ms</option>
                      </Form.Control>
                    </Col>
                    <Col sm={6} md={2}>
                      <div className="imageUpload">
                        <Form.Group>
                          <p>Update Profile Image</p>
                          <input
                            type="file"
                            name="image"
                            onChange={(e) => {
                              setFieldValue("image", e.target.files[0]);
                              var url = URL.createObjectURL(e.target.files[0]);
                              setFieldValue("user_image", url);
                            }}
                          />
                        </Form.Group>
                      </div>
                    </Col>
                    <Col sm={6} md={4}>
                      <img
                        src={values.user_image || values.image}
                        alt="user"
                        height="60px"
                        width="60px"
                        style={{ borderRadius: "50%" }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={9} md={6}>
                      <Form.Group>
                        <Form.Label>First Name*</Form.Label>
                        <Form.Control
                          type="text"
                          value={values.first_name}
                          name="first_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.first_name && !errors.first_name}
                        />
                        <ErrorMessage name="first_name">{(msg) => <div className="err_below">{msg}</div>}</ErrorMessage>
                      </Form.Group>
                    </Col>
                    <Col sm={9} md={6}>
                      <Form.Group>
                        <Form.Label>Last Name*</Form.Label>
                        <Form.Control
                          type="text"
                          value={values.last_name}
                          name="last_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.last_name && !errors.last_name}
                        />
                        <ErrorMessage name="last_name">{(msg) => <div className="err_below">{msg}</div>}</ErrorMessage>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={9} md={6}>
                      <Form.Group>
                        <Form.Label>Address Line 1*</Form.Label>
                        <Form.Control
                          type="text"
                          value={values.address_1}
                          name="address_1"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage name="address_1">{(msg) => <div className="err_below">{msg}</div>}</ErrorMessage>
                      </Form.Group>
                    </Col>
                    <Col sm={9} md={6}>
                      <Form.Group>
                        <Form.Label>Address Line 2 </Form.Label>
                        <Form.Control
                          type="text"
                          value={values.address_2}
                          name="address_2"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage name="address_2">{(msg) => <div className="err_below">{msg}</div>}</ErrorMessage>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={9} md={6}>
                      <Form.Group>
                        <Form.Label>Address Line 3 </Form.Label>
                        <Form.Control
                          type="text"
                          value={values.address_3}
                          name="address_3"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.address_3 && !errors.address_3}
                        />
                        <ErrorMessage name="address_3">{(msg) => <div className="err_below">{msg}</div>}</ErrorMessage>
                      </Form.Group>
                    </Col>
                    <Col sm={9} md={6}>
                      <Form.Group>
                        <Form.Label>City*</Form.Label>
                        <Form.Control
                          type="text"
                          value={values.city}
                          name="city"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.city && !errors.city}
                        />
                        <ErrorMessage name="city">{(msg) => <div className="err_below">{msg}</div>}</ErrorMessage>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={6}>
                      <Form.Group>
                        <Form.Label>Date Of Birth</Form.Label>
                        <Form.Control
                          type="date"
                          value={values.dob}
                          name="dob"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.dob && !errors.dob}
                        />
                        <ErrorMessage name="dob">{(msg) => <div className="err_below">{msg}</div>}</ErrorMessage>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Col sm={12} md={6}>
                        <Form.Label>Gender</Form.Label>
                        <Form.Control
                          className="dropdownform"
                          as="select"
                          name="gender"
                          value={values.gender}
                          onChange={handleChange}
                          isValid={touched.gender && !errors.gender}
                        >
                          <option value=""></option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                          <option value="other">Other</option>
                        </Form.Control>
                      </Col>

                      <ErrorMessage name="gender">{(msg) => <div className="err_below">{msg}</div>}</ErrorMessage>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={6}>
                      <Form.Group>
                        <Form.Label>Ethnicity</Form.Label>
                        <Form.Control
                          type="text"
                          value={values.ethnicity}
                          name="ethnicity"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage name="ethnicity">{(msg) => <div className="err_below">{msg}</div>}</ErrorMessage>
                      </Form.Group>
                    </Col>
                    <Col sm={12} md={6}>
                      <Form.Label>Race</Form.Label>
                      <Form.Control
                        className="dropdownform"
                        as="select"
                        name="race"
                        value={values.race}
                        onChange={handleChange}
                        isValid={touched.race && !errors.race}
                      >
                        <option value="">Select</option>
                        <option value="hinduism">Hinduism</option>
                        <option value="islam">Islam</option>
                        <option value="buddhism">Buddhism</option>
                        <option value="christianity">Christianity</option>
                        <option value="other">Other</option>
                      </Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={6}>
                      <Form.Label>Income</Form.Label>
                      <Form.Control
                        className="dropdownform"
                        as="select"
                        name="income"
                        value={values.income}
                        onChange={handleChange}
                        isValid={touched.income && !errors.income}
                      >
                        <option value="">Select</option>
                        <option value="below_1lakh">below 1 lakh</option>
                        <option value="2_lakh">2 lakh</option>
                        <option value="3_lakh">3 lakh </option>
                        <option value="4_lakh">4 lakh</option>
                        <option value="above_5lakh"> above 5 lakh</option>
                      </Form.Control>

                      <ErrorMessage name="income">{(msg) => <div className="err_below">{msg}</div>}</ErrorMessage>
                    </Col>
                    <Col sm={12} md={6}>
                      <Form.Label>Marital Status</Form.Label>
                      <Form.Control
                        className="dropdownform"
                        as="select"
                        name="marital_status"
                        value={values.marital_status}
                        onChange={handleChange}
                        isValid={touched.marital_status && !errors.marital_status}
                      >
                        <option value="">Select</option>
                        <option value="single">Single</option>
                        <option value="married">Married</option>
                        <option value="widowed">Widowed</option>
                        <option value="separated">Separated</option>
                        <option value="divorced">Divorced</option>
                      </Form.Control>

                      <ErrorMessage name="marital_status">
                        {(msg) => <div className="err_below">{msg}</div>}
                      </ErrorMessage>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={9} md={6}>
                      <Form.Group>
                        <Form.Label>State*</Form.Label>
                        <Form.Control
                          type="text"
                          value={values.state}
                          name="state"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.state && !errors.state}
                        />
                        <ErrorMessage name="state">{(msg) => <div className="err_below">{msg}</div>}</ErrorMessage>
                      </Form.Group>
                    </Col>
                    <Col sm={9} md={6}>
                      <Form.Group>
                        <Form.Label>Zip Code*</Form.Label>
                        <Form.Control
                          type="text"
                          value={values.zipcode}
                          name="zipcode"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.zipcode && !errors.zipcode}
                        />
                        <ErrorMessage name="zipcode">{(msg) => <div className="err_below">{msg}</div>}</ErrorMessage>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={9} md={6}>
                      <Form.Group>
                        <Form.Label>Country*</Form.Label>
                        <Form.Control
                          className="dropdownform"
                          as="select"
                          name="country"
                          value={values.country}
                          onChange={handleChange}
                        >
                          <option></option>
                          {countryOption}
                        </Form.Control>
                        <ErrorMessage name="country">{(msg) => <div className="err_below">{msg}</div>}</ErrorMessage>
                      </Form.Group>
                    </Col>
                    <Col sm={9} md={6}>
                      <Form.Group>
                        <Form.Label>Town</Form.Label>
                        <Form.Control
                          type="text"
                          value={values.town}
                          name="town"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.Town && !errors.Town}
                        />
                        <ErrorMessage name="town">{(msg) => <div className="err_below">{msg}</div>}</ErrorMessage>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={9} md={6}>
                      <Form.Group>
                        <Form.Label>Phone*</Form.Label>
                        <Form.Control
                          type="text"
                          value={values.phone}
                          name="phone"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.phone && !errors.phone}
                        />
                        <ErrorMessage name="phone">{(msg) => <div className="err_below">{msg}</div>}</ErrorMessage>
                      </Form.Group>
                    </Col>
                    <Col sm={9} md={6}>
                      <Form.Group>
                        <Form.Label>Email*</Form.Label>
                        <Form.Control
                          type="text"
                          value={values.email}
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.email && !errors.email}
                        />
                        <ErrorMessage name="email">{(msg) => <div className="err_below">{msg}</div>}</ErrorMessage>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={12} md={6}>
                      <Form.Group>
                        <Form.Label>Education</Form.Label>
                        <Form.Control
                          type="text"
                          value={values.education}
                          name="education"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage name="education">{(msg) => <div className="err_below">{msg}</div>}</ErrorMessage>
                      </Form.Group>
                    </Col>
                    <Col sm={12} md={6}>
                      <Form.Label>Industry</Form.Label>
                      <Form.Control
                        
                        type="text"
                        name="industry"
                        value={values.industry}
                        onChange={handleChange}
                        isValid={touched.industry && !errors.industry}
                      >
                        
                      </Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={6}>
                      <Form.Group>
                        <Form.Label>Employment</Form.Label>
                        <Form.Control
                          type="text"
                          value={values.employment}
                          name="employment"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage name="employment">{(msg) => <div className="err_below">{msg}</div>}</ErrorMessage>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={4}></Col>
                    <div className="col-md-12">
                      <button disabled={isSubmitting} type="submit">
                        Update
                      </button>
                    </div>
                  </Row>
                </Form>
              </Container>
            </div>
          )}
        />
        <ToastContainer />
      </div>
    );
  }
}
