import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import FaqService from "../../services/faqService";
import Banner from '../Banner/banner';
import BannerService from "../../services/bannerService";

export default  class Faq extends React.Component{
        constructor(props) {
            super(props);
            this.search = {start:0,perPage:1000000,searchTxt:"",searchField:""};
            this.state = {faqVal:[],selected:null,};
            
            this.bannerServ = new BannerService();
            this.faqServ = new FaqService();
    }
    
    componentDidMount() {

        window.scrollTo(0, 0);
        this.getFaqList();
        this.getBannerList();
    }
    getFaqList(){
        this.faqServ.listFaq(
                this.search.start,this.search.perPage,
                this.search.searchTxt,this.search.searchField
            )
            .then(
                (response)=>{
                    let filterFaq = {}
                    response.data && response.data.forEach((record) => {
                      if (!filterFaq[record.category_id && record.category_id.name]) {
                        filterFaq[record.category_id.name] = []
                      }
                      filterFaq[record.category_id && record.category_id.name][filterFaq[record.category_id && record.category_id.name].length] = {
                        answer : record.answer,
                        question : record.question,
                        id : record._id
                      }
                    })
                  this.setState({ faqVal: filterFaq });
                },
                (error)=>{
                    this.setState({faqVal: []});
                }
            );
    }
    getBannerList(){
        this.bannerServ.listBanner(
                this.search.start,this.search.perPage,
                this.search.searchTxt,this.search.searchField
            )
            .then(
                (response)=>{
                  this.bannerList = [];
                  for (let i = 0; i < response.data.length; i++) {
                    this.bannerList.push(response.data[i]);
                  }

                  this.setState({ bannerList: response.data });
                },
                (error)=>{
                    this.setState({bannerList: []});
                }
            );
        }
    displayFaq(){
        return (
        Object.entries(this.state.faqVal) && Object.entries(this.state.faqVal).map((val)=>[
            <strong>{val[0]}</strong>,
            val[1].map((faqs,index)=>(
            <Accordion>
                <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey={index} aria-expanded="false">
                <i className="fa fa-angle-down" aria-hidden="true"></i> <strong className = "question">{faqs.question}</strong> 
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={index}>
                <Card.Body className = "crdbdy">{faqs.answer}</Card.Body>
                </Accordion.Collapse>
                </Card>
            </Accordion>
            ))
            ]
        ))
    }

    render() {
        let faqlist = [];
        let data = "";
        let pagename = "";
        if(this.bannerList){
            for(let i = 0; i<this.bannerList.length; i++){
                if(this.bannerList[i].title === "FAQ"){
                    data = this.bannerList[i].title
                    pagename = this.bannerList[i].pagename  
                }
            }
        }
        return (
            <>
         <Banner pagename={pagename} pagelink={"/faq"} title={data} showSearch={false} />
        <div className="about-area default-padding">
                <div className="container">
                     <div className="row">
                        <div className="about-info">
                            <div className="col-md-11 info">
                            <p>
                             <h1>Frequently asked questions</h1>
                             </p>
                            <p>
                            {this.displayFaq()} 
                            </p>
                         </div>
                     </div>
                </div>
            </div>
        </div>

        </>
        )
    }
}