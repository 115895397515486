import React, { useState, useEffect } from "react";
import { Row, Tab, ListGroup, Col, Form } from "react-bootstrap/";
import MessageInput from "./MessageInput";

function Message(props) {
  const [course, setcourse] = useState("");
  const [innerWidth, setinnerWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", () => {
      setinnerWidth(window.innerWidth);
    });
  });
  const courseListShowBasedOnScreenSize = () => {
    return (
      <div className="input-group" style={{ marginBottom: "10px" }}>
        <div className="input-group-btn">
          <button
            type="button"
            className="btn btn-default dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Course List <span className="caret"></span>
          </button>
          <ul style={{ marginTop: "0px" }} className="dropdown-menu">
            {props.courses.map((el) => (
              <li>
                <a
                  style={{ cursor: "pointer" }}
                  key={el.course?._id}
                  onClick={() => {
                    setcourse(el.course);
                  }}
                >
                  {el.course.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  };
  return (
    <div className={`course-details-area `}>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            {innerWidth < 400 ? (
              courseListShowBasedOnScreenSize()
            ) : (
                <div className="list-group">
                  {props.courses.map((el) => (
                    < a
                      key={el._id}
                      onClick={() => {
                        setcourse(el.course);
                      }}
                      className={`list-group-item  ${el.course._id == course._id ? "active" : null}`}
                    >
                      {el.course.name}
                    </a>
                  ))}
                </div>
              )}
          </div>

          <div className="col-md-8">
            <div className="">
              <aside>{course ? <MessageInput course={course} /> : null}</aside>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default Message;
