import React from "react";

export const Error404 = () => {
  return (
    <div id="mainn">
      <div class="fof">
        <h1>Error 404</h1>
        <p>
          Unfortunately site content is not avilable in your country. Please wait till the time services get started in
          your region.
        </p>
      </div>
    </div>
  );
};
