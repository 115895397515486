import util from '../utils/util';

export default  class FaqService{

    getFaq(id) {
        return util.sendApiRequest("/faq/"+id,"GET",true).then(
            (response)=>{
                return response;  
            },
            (error)=>{
                throw new Error(error);
            },
        );
    }
    listFaq(start,length,searchTxt,searchField) {
        let url = "/faq/list/"+start+"/"+length;
        if(searchTxt && searchField){
         url = "/faq/list/"+start+"/"+ length+"/"+searchTxt+"/"+searchField.toLowerCase();
        }
        
         return util.sendApiRequest(url, "GET",true).then(
             (response)=>{
                 return response;
             },
             (error)=>{
                 throw new Error(error);
             },
         );
     }
}