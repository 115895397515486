import util from "../utils/util";

export default class InvoiceGeneratorService {
  getInvoice(id) {
    return util
      .sendApiRequest("/create-pdf/", "POST", true, { id, action: 1 })
      .then(
        response => {},
        error => {}
      );
  }
}
