import React from "react";
import { withRouter } from "react-router-dom";

class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.searchTxt = "";
  }

  handleChange(e) {
    e.preventDefault();
    this.searchTxt = e.target.value;
  }

  searchRedirect(e) {
    e.preventDefault();
    if (this.searchTxt) {
      this.props.history.push("/course/" + this.searchTxt);
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.match.params.searchTxt != this.searchTxt) {
      this.searchTxt = newProps.match.params.searchTxt;
    }
  }

  render() {
    return (
      <div>
        <form
          onSubmit={(e) => {
            this.searchRedirect(e);
          }}
        >
          <input
            type="text"
            placeholder="Enter course name"
            onChange={this.handleChange.bind(this)}
            className="form-control"
            name="text"
            defaultValue={this.searchTxt}
          />
          <button type="submit">Search Courses</button>
        </form>
      </div>
    );
  }
}

export default withRouter(SearchBar);
