import React, { Component } from "react";
import { Formik, ErrorMessage } from "formik";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import UserService from "../../services/userService";
import { Redirect } from "react-router-dom";
export default class PasswordChanged extends Component {
  constructor(props) {
    super(props);
    this.user_id = window.user.data._id;
    this.image = "";
    this.state = {
      redirect: false,
    };

    this.schema2 = Yup.object({
      password: Yup.string().required("Required"),
      newPassword: Yup.string().required("Required"),
      verifyPassword: Yup.string("Required")
        .required()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
    });

    this.userServ = new UserService();
  }
  scrollUp(amountToScroll) {
    this.setState({
      scrollPosition: this.state.scrollPosition + amountToScroll,
    });
    window.scrollTo(0, this.state.scrollPosition);
  }
  submitPasswordChangeForm(values, actions) {
    if (this.user_id) {
      this.userServ.editUser(values).then(
        (response) => {
          actions.setSubmitting(false);
          toast.success("Password updated");

          this.props.handleUserName(response);
          window.scroll(30, 30);
          actions.resetForm();
          setTimeout(() => {
            this.setState({ redirect: true });
          }, 1000);
        },
        (error) => {
          this.setState({ redirect: false });

          toast.error(error.message);
        }
      );
    }
  }

  render() {
    const path = this.props?.location?.pathname;
    if (path == "/change-password" && this.state.redirect) {
      return <Redirect to="/" />;
    }

    const localuser = localStorage.getItem("user");
    const parseduser = JSON.parse(localuser);
    const user = parseduser.data;

    return (
      <div>
        <Formik
          validationSchema={this.schema2}
          initialValues={{ password: "", newPassword: "", verifyPassword: "", ...user }}
          enableReinitialize={true}
          onSubmit={this.submitPasswordChangeForm.bind(this)}
          render={({ values, errors, status, touched, handleBlur, handleChange, handleSubmit, isSubmitting }) => (
            <div className="address addresslist">
              <Container>
                <Form style={path == "/change-password" ? { margin: "7rem" } : null} onSubmit={handleSubmit}>
                  <Row>
                    <Col sm={9} md={6}>
                      <Form.Group>
                        <Form.Control
                          type="password"
                          value={values.newPassword}
                          placeholder="Choose Password"
                          name="newPassword"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.newPassword && !errors.newPassword}
                        />
                        <ErrorMessage name="newPassword">
                          {(msg) => <div className="err_below">{msg}</div>}
                        </ErrorMessage>
                      </Form.Group>
                    </Col>
                    <Col sm={9} md={6}>
                      <Form.Group>
                        <Form.Control
                          type="text"
                          value={values.verifyPassword}
                          placeholder="Retype Password"
                          name="verifyPassword"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage name="verifyPassword">
                          {(msg) => <div className="err_below">{msg}</div>}
                        </ErrorMessage>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={9} md={6}>
                      <Form.Group>
                        <Form.Control
                          type="password"
                          value={values.password}
                          placeholder="Old Password"
                          name="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage name="password">{(msg) => <div className="err_below">{msg}</div>}</ErrorMessage>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={4}></Col>
                    <div className="col-md-12">
                      <button className="password-change" type="submit">
                        Update
                      </button>
                    </div>
                  </Row>
                </Form>
              </Container>
            </div>
          )}
        />
        <ToastContainer />
      </div>
    );
  }
}
