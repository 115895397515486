import util from "../utils/util";

export default class UserService {
  login(email, password) {
    let body = { email, password };
    return util.sendApiRequest("/user/login", "POST", true, body).then(
      (response) => {
        localStorage.setItem("user", JSON.stringify(response));
        window.user = response;
        return window.user;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }

  logout() {
    localStorage.clear();
    window.user = null;
  }

  getUser(id, withCourse) {
    return util.sendApiRequest("/user/" + id + (withCourse ? "/1" : ""), "GET", true).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }

  editUser(user, newPassword, verifyPassword) {
    return util.sendApiRequest("/user", "PUT", true, user, newPassword, verifyPassword).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }

  listUser(data, userlist) {
    const user = Object.keys(data).reduce((object, key) => {
      if (data[key] !== "") {
        object[key] = data[key];
      }
      return object;
    }, {});
    return util
      .sendApiRequest("/user/list/" + userlist, "POST", true, user)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

  // listUser(start, length, searchTxt, searchField) {
  //   let url = "/user/list/" + start + "/" + length;
  //   if (searchTxt && searchField) {
  //     url = "/user/list/" + start + "/" + length + "/" + searchTxt + "/" + searchField.toLowerCase();
  //   }

  //   return util.sendApiRequest(url, "GET", true).then(
  //     (response) => {
  //       return response;
  //     },
  //     (error) => {
  //       throw new Error(error);
  //     }
  //   );
  // }
  addUser(user) {
    return util.sendApiRequest("/user", "POST", true, user).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }
  deleteUser(_id) {
    let id = { _id };
    return util.sendApiRequest("/user/delete", "POST", true, id).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }
  reset_password(newPassword, verifyPassword, token) {
    return util
      .sendApiRequest("/user/reset/" + token, "POST", true, {
        newPassword: newPassword,
        verifyPassword: verifyPassword,
      })
      .then(
        (response) => {
          return response;
        },
        (error) => {
          throw new Error(error);
        }
      );
  }
  forget_password(email) {
    return util.sendApiRequest("/user/forgot", "POST", true, { email: email }).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }
  getCertificate(certificateData) {
    return util.sendApiRequest("/certificate/", "POST", true, certificateData).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }

  // userFeedback form submission
  sumbitUserFeedBack(data) {
    return util.sendApiRequest("/user/feedback/", "POST", true, data).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }
}
