import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { Form, Row, Col, Button } from "react-bootstrap/";
import Iframe from "react-iframe";

function PreviewQuizze(props) {
  const [currentQuestion, setcurrentQuestion] = useState(0);
  const [question, setquestion] = useState({ option: [], total_duration: null });
  const [showFinalResult, setshowFinalResult] = useState(false);
  useEffect(() => {
    props?.data?.length && setquestion(props?.data[currentQuestion]);
  }, [currentQuestion, props.data]);

  //question type vedio , audio , pdf etc
  const handleQuestionType = (values) => {
    return (
      <Iframe
        url={values?.file_path}
        width="100%"
        height="300px"
        id="myId"
        className="myClassname"
        display="initial"
        position="relative"
        allowFullScreen={true}
        controls
      />
      // .replace('http:','https:')
    );
  };
  const handletexttype = (values) => {
    return (
      <li>
        <input style={{ display: "none" }} type="text" value={values?.question} name="question" />

        <Form.Control
          type="text"
          name="user_answers"
          placeholder="Answer"
          disabled={true}
          value={values.user_answers}
        />
        <p>
          Correct Answer: <span>{values?.option[0]}</span>
        </p>
      </li>
    );
  };

  //handle single mcq and multiple mcq question
  const showmcq = (values) => {
    return values.option.map((el2, i2) => {
      if (values?.answerType == "single_mcq" || values?.answerType == "true/false") {
        return (
          <div className="col-md-6">
            <li key={el2 + i2 + "abc"}>
              <input style={{ display: "none" }} type="text" value={values?.question} name="question" />
              {values.correct_answer.includes(el2) && (
                <div className="checked-icon">
                  <i className="fas fa-check"></i>
                </div>
              )}
              <Form.Check
                style={{ display: "inline-grid" }}
                type="radio"
                value={el2}
                name="user_answers"
                checked={values.user_answers[0]?.includes(el2)}
                disabled={!values?.user_answers.length ? true : false}
              />
              <span className="radio-button">{el2}</span>
            </li>
          </div>
        );
      } else if (values?.answerType == "multiple_mcq") {
        return (
          <div className="col-md-6">
            <li key={el2 + i2 + "abc"}>
              <input style={{ display: "none" }} type="text" value={values?.question} name="question" />
              {values.correct_answer.includes(el2) && (
                <div className="checked-icon">
                  <i className="fas fa-check"></i>
                </div>
              )}
              <Form.Check
                style={{ display: "inline-grid" }}
                type="checkbox"
                inline
                value={el2}
                name="user_answers"
                checked={values.user_answers?.indexOf(el2) !== -1 ? true : false}
                disabled={!values?.user_answers.length ? true : false}
              />
              <span className="radio-button">{el2}</span>
            </li>
          </div>
        );
      }
    });
  };

  return (
    <Formik initialValues={question} enableReinitialize={true} onSubmit={() => {}}>
      {({ values, handleChange, handleBlur, handleSubmit, touched, errors, setFieldValue, resetForm }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <div className="panel-heading">
              <h4 className="panel-title">
                Question {currentQuestion + 1} of {props?.data?.length}
              </h4>
            </div>
            <div className="panel-body">
              <h4 className="panel-title">{values?.question}</h4>
              <br />
              <ul>
                <div className="row"></div>
                <Form.Group>{values?.answerType == "text" ? handletexttype(values) : showmcq(values)}</Form.Group>
              </ul>
            </div>
            <Row>
              <Col sm={12} md={4}>
                <Button
                  className="btn btn-primary btn-block setbgcolor"
                  type="submit"
                  onClick={() =>
                    props?.data?.length - 1 !== currentQuestion
                      ? setcurrentQuestion((c) => c + 1)
                      : setshowFinalResult(true)
                  }
                >
                  {props?.data?.length - 1 !== currentQuestion ? "Next" : "Last"}
                </Button>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
}

export default PreviewQuizze;
