import React, { useEffect, useState } from "react";
import messageService from "../../services/messageService";
import { Form, Row, Col, Button } from "react-bootstrap/";
import ScrollToBottom from "react-scroll-to-bottom";
import moment from "moment";
function MessageInput(props) {
  const [messages, setmessages] = useState({ _id: "", messages: [] });
  const [message, setmessage] = useState("");

  const { _id: courseId } = props.course;
  const teacherId = props.course?.created_by?._id;
  const messageServ = new messageService();
  const sendMessage = async (data) => {
    data.preventDefault();
    let messageData = {
      userId: window.user.data._id,
      teacherId: teacherId,
      courseId: courseId,
      messages: [
        {
          message: message,
          sender_type: 2,
        },
      ],
    };
    if (messages?._id) {
      messageData._id = messages._id;
    }
    try {
      let res = await messageServ.sendMessage(messageData);
      setmessages((prevState) => ({ ...prevState, ...res.data }));
      setmessage("");
    } catch (error) {}
  };
  useEffect(() => {
    async function fetch() {
      let result = await messageServ.getmessages(courseId, teacherId, window.user.data._id);
      setmessages(result.data);
    }
    fetch();
  }, [props.course]);

  return (
    <div style={{ border: "1px solid #ccc", height: "32.3rem", borderRadius: "4px" }}>
      <div className=" messages" style={{ marginTop: "7px" }}>
        <ScrollToBottom className="messages" behavior="smooth">
          {messages?.messages?.map((el, i) => {
            if (el.sender_type == 2) {
              return (
                <>
                  <div
                    key={"" + Math.random() * i + 4}
                    className={`media ${i !== messages.messages.length - 1 ? "mediabutton" : null}`}
                  >
                    <div className="media-right ">
                      <img
                        className="media-object cstmimg"
                        src={window.user.data.image || "/chatlogo2.png"}
                        alt="img"
                      />
                    </div>
                    <div className="media-body">
                      <h5 className="media-heading">
                        You <span className="time">{moment(el.time).format("hh:mm:a")}</span>
                      </h5>
                      <p>{el.message}</p>
                    </div>
                  </div>
                </>
              );
            } else {
              return (
                <>
                  <div key={"" + i} className={`media ${i !== messages.messages.length - 1 ? "mediabutton" : null}`}>
                    <div className="media-right ">
                      <img
                        className="media-object cstmimg"
                        src={messages.teacherId?.image || "/chatlogo1.jpg"}
                        alt="img"
                      />
                    </div>
                    <div className="media-body">
                      <h5 className="media-heading">
                        {props.course?.created_by?.first_name} <span>{moment(el.time).format("hh:mm:a")}</span>
                      </h5>
                      <p>{el.message}</p>
                    </div>
                  </div>
                </>
              );
            }
          })}
        </ScrollToBottom>
        {!messages?.messages.length ? (
          <div>
            <p style={{ textAlign: "center", marginTop: "-100px" }}>Ask your Query...</p>
          </div>
        ) : null}
      </div>
      <div className="input-group" style={{ marginTop: "14px" }}>
        <input
          type="text"
          className="form-control"
          type="text"
          value={message}
          onChange={({ target: { value } }) => setmessage(value)}
          onKeyPress={(event) => (event.key === "Enter" ? sendMessage(event) : null)}
          placeholder="Type a message..."
        />
        <span className="input-group-btn">
          <button
            className="btn btn-default"
            style={{ height: "5rem", width: "9rem", background: "#ccc" }}
            type="button"
            onClick={sendMessage}
          >
            Send <i className="fas fa-paper-plane"></i>
          </button>
        </span>
      </div>
    </div>
  );
}

export default MessageInput;
