import React from "react";
import { Redirect } from "react-router-dom";
import userservice from "../services/userService";

export default class Logout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.userv = new userservice();
  }

  render() {
    this.userv.logout();
      this.props.handlerLoginParent(false);
      return <Redirect to="/" />;
  }
}
